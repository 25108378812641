
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import EstimateService from "@/services/work/estimate.service";
import core from "@/core";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import EstimateListItemComponent from "@/components/estimate/ListItemComponent.vue";
import { PlaceModel } from "@/models/place/place.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
    dong: "동 ",
    ho: "호 ",
    customerPhone: "휴대전화 ",
    customerName: "고객명 ",
    scheduleYn: "일정상태 ",
    contractStatus: "계약상태 ",
    paymentYn: "결제상태 ",
    confirmPaymentYn: "결제확인 ",
    existsPaymentYn: "결제금액 여부",
    balance: "잔금 ",
  },
});

export default mixins(MixinsPageForm, MixinsStorage, MixinsScroll, MixinsTable).extend({
  name: "EstimateSearch",
  components: { EstimateListItemComponent },
  data: () => ({
    history: "search",
    storageItems: {
      type: "estimate-search",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "createdAt",
        searchColumns: {},
      },
    },
    historyItems: [] as any,
    historyFilterItems: [] as any,
    submitSearchKeyword: null as string | null,
    searchKeyword: null as string | null,
    searchKeywordFocus: false,
    searchKeywordFocusTime: new Date().getTime(),
    searchType: "keyword", // keyword, detail
    preViewType: "",
    viewType: "history", // history, detail, result
    keywordPromise: {
      wait: null as any,
      resolve: null as any,
    },
    detailSearch: {
      placeName: "",
      placeId: "",
      dong: "",
      ho: "",
      customerPhoneLike: "",
      customerName: "",
      scheduleYn: "",
      contractStatus: "",
      paymentYn: "",
      confirmPaymentYn: "",
      balance: "",
      existsPaymentYn: "",
    },
    modal: {
      place: {
        visible: false,
      },
    },
    placeList: [] as PlaceModel[],
    type: "",
    sort: {
      orderColumnName: "createdAt",
      order: "desc",
    },
  }),
  mounted() {
    this.type = this.$route.query.type as string;

    this.$nextTick(async () => {
      this.placeList = await this.$store.getters["app/getPlaceList"]();

      const sortStorage = await this.$store.dispatch("storage/getStorageItem", "estimate");
      if (sortStorage) {
        if (sortStorage.sort) {
          const sort = (this.sort = sortStorage.sort);
          this.table.request.orderColumnName = sort.orderColumnName;
          this.table.request.order = sort.order;
        }
      }

      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItems = items;
      } else {
        this.setStorageItem(this.history, this.historyItems);
      }

      // 테이블 초기화
      this.initTable({
        service: EstimateService,
        serviceFunctionKey: "getTable",
        itemHeight: 48,
      });

      this.changedSearchKeyword(this.searchKeyword);
      this.keywordPromise.wait = new Promise((resolve: any, reject) => {
        this.keywordPromise.resolve = resolve;
      });
      this.focusIn();
      this.moveFocus("searchKeyword");
    });
  },

  computed: {
    tableDataList() {
      return this.table.response.data;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.updatePreVmEvent(event.result, event.item);

      if (event.result === UpdateEventType.UPDATE) {
        this.addTableItem("id", event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        //console.log("event : ", event);
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }

    const vm = this as any;
    this.addRouterBeforeTaskItem(() => {
      // console.log("viewType : ", vm.viewType, ", searchType : ", this.searchType);
      if (vm.searchType === "detail") {
        if (vm.preViewType === "result") {
          vm.viewType = "result";
        } else {
          vm.searchType = "keyword";
        }
        return false;
      }
      return true;
    });
  },

  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    searchKeyword(val) {
      this.changedSearchKeyword(val);
      //console.log("changed searchKeyword");
      this.keywordPromise.resolve();
    },
    searchType(type) {
      if (type === "keyword") {
        this.viewType = "history";
      } else if (type === "detail") {
        this.viewType = "detail";
      } else {
        console.log("Unknown Type : ", type);
      }
    },
    viewType(val, prev) {
      this.preViewType = prev;

      // viewType 변경시, 스크롤바 위로 이동
      const $el = this.$refs.body as any;
      if ($el != null) {
        $el.scrollTop = 0;
      }
    },
    "detailSearch.customerPhoneLike"(val, prev) {
      this.detailSearch.customerPhoneLike = core.utils.format.hyphenPhone(val);
    },
    "detailSearch.placeName"(val) {
      if (this.isBlank(val)) {
        this.detailSearch.placeId = "";
      }
    },
  },
  methods: {
    getHistoryDate(item: any) {
      return this.dateElapsedTimeFormat(
        core.date.instance(new Date(item.date)).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    isSearch() {
      return this.table.request.draw > 0;
    },
    isResultEmpty() {
      return this.table.request.draw > 0 && this.table.response.data.length == 0;
    },
    changedSearchKeyword(searchKeyword) {
      const isBlank = this.isBlank(searchKeyword);
      const filterItems = [] as any;

      this.historyItems.some((item: any) => {
        if (isBlank || item.keyword.indexOf(searchKeyword) > -1) {
          filterItems.push(item);
        }
        if (filterItems.length === 20) {
          return true;
        }
      });
      this.historyFilterItems = filterItems;
      if (filterItems.length > 0 && this.searchKeywordFocus) {
        // TODO : 이력정보 표시?
        //this.visibleHistory = true;
      }
    },
    focusIn() {
      this.searchKeywordFocus = true;
      this.searchKeywordFocusTime = new Date().getTime();
      this.viewType = "history";
    },
    focusOut() {
      if (new Date().getTime() - this.searchKeywordFocusTime > 100) {
        this.searchKeywordFocus = false;
      }
    },
    submit() {
      (this.$refs.searchKeyword as any).blur();
      this.searchKeywordFocus = false;

      const searchKeyword = this.searchKeyword as string;
      if (this.isNotBlank(searchKeyword)) {
        this.viewType = "result";

        this.submitSearchKeyword = searchKeyword;
        //console.log("submit searchKeyword : ", this.searchKeyword);

        // 키워드 스토리지 동일 항목 제외, 최대 100개 저장
        this.historyItems.some((item: any, index) => {
          if (item.keyword === searchKeyword) {
            this.historyItems.splice(index, 1);
            return true;
          }
        });

        const diffLength = this.historyItems.length - 99;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            this.historyItems.splice(this.historyItems.length - 1, 1);
          }
        }

        this.historyItems.unshift({
          keyword: searchKeyword,
          date: new Date().getTime(),
        });
        this.saveStorageItems();

        const searchColumns = this.keywordToSearchColumns(searchKeyword);
        if (this.type === "issue") {
          searchColumns.issue = true;
        } else if (this.type === "deleted") {
          searchColumns.deletedAtIsNull = false;
        } else if (this.type === "me") {
          searchColumns.createdBy = this.myUser.username;
        }

        this.clearTable();
        this.loadTable(searchColumns);
      } else {
        core.alert.show({
          title: "알림",
          body: "키워드를 입력하세요!",
        });
      }
    },

    async submitDetail() {
      if (await this.validate()) {
        const params = core.utils.deepCopy(this.detailSearch);
        delete params.placeName;
        const existsPaymentYn = params.existsPaymentYn;
        delete params.existsPaymentYn;
        if (this.isNotBlank(existsPaymentYn)) {
          if (existsPaymentYn === "Y") {
            params.paymentGreaterThan = 0;
          } else if (existsPaymentYn === "N") {
            params.paymentLessThanEqual = 0;
          } else {
            console.log("invalid existsPaymentYn : ", existsPaymentYn);
          }
        }
        for (const key of Object.keys(params)) {
          const value = params[key];
          if (this.isBlank(value)) {
            delete params[key];
          } else if (key === "customerPhoneLike") {
            params[key] = params[key].replaceAll("-", "");
          } else if (key === "balance") {
            params[key] = Number(params[key]) * 10000;
          }
        }
        if (Object.keys(params).length === 0) {
          core.alert.show({
            title: "알림",
            body: "입력된 조건이 1개 이상이어야 합니다.",
          });
        } else {
          //console.log("params : ", params);
          this.viewType = "result";
          this.clearTable();

          if (this.type === "issue") {
            params.issue = true;
          } else if (this.type === "deleted") {
            params.deletedAtIsNull = false;
          } else if (this.type === "me") {
            params.createdBy = this.myUser.username;
          }
          this.loadTable(params);
        }
      }
    },
    async selectHistory(item: any) {
      //console.log("selectHistory : ", item);

      const changedSearchKeyword = this.searchKeyword !== item.keyword;

      if (changedSearchKeyword) {
        this.searchKeyword = item.keyword;
        await this.keywordPromise.wait;
      }

      if (this.submitSearchKeyword !== item.keyword) {
        if (!changedSearchKeyword) {
          this.changedSearchKeyword(this.searchKeyword);
        }
        this.submit();
      } else {
        this.changedSearchKeyword(this.searchKeyword);
        if (this.viewType === "history") {
          this.viewType = "result";
        }
      }
    },

    keywordToSearchColumns(searchKeyword: string) {
      const searchColumns = {} as any;

      // 1. 휴대전화번호 형식 분류
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const phoneResult = core.utils.format.textToPhoneNumber(searchKeyword) as any;
        // console.log("phoneResult : ", phoneResult);
        if (phoneResult != null) {
          console.log(`replace content ${searchKeyword} -> ${phoneResult.replaceContent}`);
          searchKeyword = phoneResult.replaceContent;

          if (phoneResult.list.length > 1) {
            searchColumns.customerPhoneList = phoneResult.list.join(",");
          } else {
            searchColumns.customerPhone = phoneResult.list[0];
          }
        }
      }

      // 2. 동호수 형식 분류, 101동 101호, 101-101, 101.101
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const dongHoResult = core.utils.format.textToDongHo(searchKeyword) as any;
        // console.log("dongHoResult : ", dongHoResult);
        if (dongHoResult != null) {
          console.log(`replace content ${searchKeyword} -> ${dongHoResult.replaceContent}`);
          searchKeyword = dongHoResult.replaceContent;
          if (dongHoResult.list.length > 1) {
            const dongList = [] as any;
            const hoList = [] as any;
            dongHoResult.list.forEach((dongHo) => {
              dongList.push(dongHo.dong);
              hoList.push(dongHo.ho);
            });
            searchColumns.dongList = dongList.list.join(",");
            searchColumns.hoList = hoList.list.join(",");
          } else {
            searchColumns.dong = dongHoResult.list[0].dong;
            searchColumns.hoStartLike = dongHoResult.list[0].ho;
          }
        }
      }

      // 3. 아파트 이름 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const placeIdList = [] as any;
        const placeNameList = searchKeyword.split(" ");
        placeNameList.forEach((placeName) => {
          this.placeList.forEach((place) => {
            if (place.name.indexOf(placeName) > -1) {
              placeIdList.push(place.id);
              searchKeyword = searchKeyword.replaceAll(placeName, "").trim();
            }
          });
        });

        if (placeIdList.length > 0) {
          if (placeIdList.length > 1) {
            searchColumns.placeIdList = placeIdList.join(",");
          } else {
            searchColumns.placeId = placeIdList[0];
          }
        }
      }

      // 4. 고객명 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const customerNameList = searchKeyword.split(" ");
        if (customerNameList.length > 0) {
          if (customerNameList.length > 1) {
            searchColumns.customerNameList = customerNameList.join(",");
          } else if (this.isNotBlank(customerNameList[0])) {
            searchColumns.customerName = customerNameList[0];
          }
        }
      }
      return searchColumns;
    },
  },
});

import core from "@/core";
import store from "@/store";
import ScheduleTempService from "@/services/work/schedule.temp.service";
import { FilterScheduleViewType } from "@/models/schedule";

class ScheduleService {
  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/schedule`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            //console.log("before convert : ", data);
            this.convertSchedule(data.schedule);
            //console.log("convertSchedule : ", data);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/schedule/${id}`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateUserList(id: number, userList: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ userList: userList });
      core.http
        .putJson(`/api/work/schedule/${id}/user-list`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateScheduleComplete(id: any, complete: boolean) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ complete: complete });
      core.http
        .putJson(`/api/work/schedule/${id}/complete`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateScheduleTopFixed(id: any, topFixed: boolean) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ topFixed: topFixed });
      core.http
        .putJson(`/api/work/schedule/${id}/top-fixed`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateDate(id: any, allDay: boolean, startAt: string, endAt: string) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ startAt: startAt, endAt: endAt, allDay: allDay });
      core.http
        .putJson(`/api/work/schedule/${id}/date`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateMemo(id: number, memo: string) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ memo: memo });
      core.http
        .putJson(`/api/work/schedule/${id}/memo`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateColor(id: number, color: string) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ color: color });
      core.http
        .putJson(`/api/work/schedule/${id}/color`, strParams)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/schedule/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/${id}`)
        .then((data: any) => {
          if (data.schedule != null) {
            this.convertSchedule(data.schedule);
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(searchColumns: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/table`, null, searchColumns)
        .then(async (data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  async getSearchColumnsAndViewType(searchColumns: any, filterViewType?: string) {
    if (filterViewType == null) filterViewType = FilterScheduleViewType.ALL;

    if (filterViewType === FilterScheduleViewType.DEFAULT) {
      return await this.getBySearchColumns(searchColumns, false);
    } else if (filterViewType === FilterScheduleViewType.TEMP) {
      const scheduleList = [];
      await this.getTempScheduleList(false, searchColumns, scheduleList);
      return scheduleList;
    } else {
      return await this.getBySearchColumns(searchColumns, true);
    }
  }

  getBySearchColumns(searchColumns: any, loadTempSchedule?: boolean) {
    if (loadTempSchedule == null) loadTempSchedule = false;
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule`, null, searchColumns)
        .then(async (data: any) => {
          if (data.scheduleList != null) {
            if (loadTempSchedule) {
              await this.getTempScheduleList(false, searchColumns, data.scheduleList);
            }
            await this.convertScheduleList(searchColumns, data.scheduleList);
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSimpleBySearchColumns(searchColumns: any, loadTempSchedule?: boolean) {
    if (loadTempSchedule == null) loadTempSchedule = false;
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/simple`, null, searchColumns)
        .then(async (data: any) => {
          if (data.scheduleList != null) {
            if (loadTempSchedule) {
              await this.getTempScheduleList(true, searchColumns, data.scheduleList);
            }
            await this.convertScheduleList(searchColumns, data.scheduleList);
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  async getSimpleBySearchColumnsAndViewType(searchColumns: any, filterViewType?: string) {
    if (filterViewType == null) filterViewType = FilterScheduleViewType.ALL;

    if (filterViewType === FilterScheduleViewType.DEFAULT) {
      return await this.getSimpleBySearchColumns(searchColumns, false);
    } else if (filterViewType === FilterScheduleViewType.TEMP) {
      const scheduleList = [];
      await this.getTempScheduleList(true, searchColumns, scheduleList);
      return scheduleList;
    } else {
      return await this.getSimpleBySearchColumns(searchColumns, true);
    }
  }

  async getTempScheduleList(isSimple: boolean, searchColumns: any, scheduleList: any) {
    // 임시 일정 조회
    const supportKeys = [
      "categoryId",
      "categoryIdList",
      "title",
      "titleList",
      "customerPhone",
      "customerPhoneList",
      "customerPhoneLike",
      "customerPhoneLikeList",
      "placeId",
      "placeIdList",
      "dong",
      "dongList",
      "ho",
      "hoList",
      "startDate",
      "endDate",
      "workType",
      "workTypeList",
      "teamId",
    ];
    const scheduleTempParams = {};
    for (const key of Object.keys(searchColumns)) {
      if (supportKeys.indexOf(key) > -1) {
        scheduleTempParams[key] = searchColumns[key];
      }
    }
    let scheduleTempList = null as any;
    if (isSimple) {
      scheduleTempList = (await ScheduleTempService.getSimpleBySearchColumns(
        scheduleTempParams
      )) as any;
    } else {
      scheduleTempList = (await ScheduleTempService.getBySearchColumns(scheduleTempParams)) as any;
    }
    scheduleTempList.forEach((schedule) => {
      schedule.isTemp = true;
      schedule.title = `[입주예정] ${schedule.title}`;
      scheduleList.push(schedule);
    });
  }

  async convertScheduleList(searchColumns: any, scheduleList: any) {
    const items = await store.dispatch("storage/getStorageItem", "schedule");
    let orderColumnName = "";
    let order = "";
    let listViewType = "user";
    if (items != null) {
      if (items.sort != null) {
        orderColumnName = items.sort.orderColumnName;
        order = items.sort.order;
      }
      if (items.listViewType != null) {
        listViewType = items.listViewType;
      }
    }

    await this.sortScheduleList(searchColumns, scheduleList, orderColumnName, order, listViewType);
  }

  async sortScheduleList(
    searchColumns: any,
    scheduleList: any,
    orderColumnName?: string,
    order?: string,
    listViewType?: string
  ) {
    if (orderColumnName == null) orderColumnName = "title";
    if (order == null) order = "asc";
    if (listViewType == null) listViewType = "user";

    if (searchColumns.startDate != null && searchColumns.endDate != null) {
      const startMoment = core.date.instance(searchColumns.startDate);
      const endMoment = core.date.instance(searchColumns.endDate);
      const diffDays = endMoment.diff(startMoment, "days");
      //console.log("diff days : ", diffDays);
      if (diffDays <= 1) {
        this.scheduleListSortedByTime(scheduleList, orderColumnName, order, startMoment, endMoment);

        if (listViewType === "user") {
          const users = new Map();
          users.set("top", { name: "상단고정", scheduleList: [] });
          users.set("default", { name: "공통", scheduleList: [] });

          scheduleList.forEach((schedule) => {
            if (schedule.userList != null && schedule.userList.length > 0) {
              // 담당자 정렬
              const user = {
                id: "",
                name: "",
                scheduleList: [],
              };
              const mainUserList = [] as any;
              const subUserList = [] as any;
              schedule.userList.forEach((user) => {
                if (user.type === "MAIN") {
                  mainUserList.push(user);
                } else {
                  subUserList.push(user);
                }
              });
              subUserList.sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                return 0;
              });

              mainUserList.forEach((_user) => {
                user.id += _user.id + ",";
                user.name += _user.name + ",";
              });
              if (user.id.length > 1) {
                user.id = user.id.substr(0, user.id.length - 1);
              }
              if (user.name.length > 1) {
                user.name = user.name.substr(0, user.name.length - 1);
              }
              if (subUserList.length > 0) {
                user.name += "(";
                subUserList.forEach((_user, index) => {
                  user.id += (index > 0 ? "," : "") + _user.id;
                  user.name += (index > 0 ? "," : "") + _user.name;
                });
                user.name += ")";
              }
              //console.log("user : ", user);

              let userItem = users.get(user.id);
              if (userItem == null) {
                userItem = core.utils.deepCopy(user);
                users.set(user.id, userItem);
              }
              userItem.scheduleList.push(schedule);
              users.set(user.id, userItem);

              // mainUserList.forEach((user) => {
              //   let userItem = users.get(user.id);
              //   if (userItem == null) {
              //     userItem = core.utils.deepCopy(user);
              //     users.set(user.id, userItem);
              //   }
              //   if (userItem.scheduleList == null) {
              //     userItem.scheduleList = [];
              //   }
              //   userItem.scheduleList.push(schedule);
              // });
            } else {
              if (schedule.topFixed) {
                const userItem = users.get("top");
                userItem.scheduleList.push(schedule);
              } else {
                const userItem = users.get("default");
                userItem.scheduleList.push(schedule);
              }
            }
          });

          scheduleList.length = 0;
          for (const user of users.values()) {
            this.updateScheduleTitle(user.scheduleList);

            user.scheduleList.forEach((schedule, index) => {
              if (index === 0) {
                schedule.showMainTitle = true;
                schedule.mainTitle = user.id == null ? user.name : `${user.name} 담당자 일정`;
                schedule.isUserTitle = user.id != null;
                scheduleList.push(schedule);
              } else {
                schedule.showMainTitle = false;
                schedule.isUserTitle = false;
                delete schedule.mainTitle;
                scheduleList.push(schedule);
              }
              if (schedule.userList) {
                schedule.userList.some((user) => {
                  if (user.type === "MAIN") {
                    schedule.mainUserId = user.id;
                    return true;
                  }
                });
              }
            });
          }
        } else {
          scheduleList.sort((a: any, b: any) => {
            if (!a.allDay && !b.allDay) {
              // 시간별
              const aMoment = core.date.instance(a.startAt);
              const bMoment = core.date.instance(b.startAt);
              if (aMoment.isBefore(bMoment)) return -1;
              else if (aMoment.isAfter(bMoment)) return 1;
            }

            const aValue = core.utils.getValueFromObjectId(a, String(orderColumnName));
            const bValue = core.utils.getValueFromObjectId(b, String(orderColumnName));
            if (aValue != null && bValue != null) {
              if (order === "asc") {
                // 오름차순
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
              } else {
                // 내림차순
                return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
              }
            } else {
              return -1;
            }
          });
          // scheduleList.length = 0;
          const fixedScheduleList = [] as any;
          const normalScheduleList = [] as any;
          scheduleList.forEach((schedule) => {
            if (
              schedule.topFixed &&
              (schedule.userList == null || schedule.userList.length === 0)
            ) {
              fixedScheduleList.push(schedule);
            } else {
              normalScheduleList.push(schedule);
            }
          });
          scheduleList.length = 0;
          //console.log("fixedScheduleList : ", fixedScheduleList);
          fixedScheduleList.forEach((schedule) => {
            scheduleList.push(schedule);
          });
          normalScheduleList.forEach((schedule) => {
            scheduleList.push(schedule);
          });
          this.updateScheduleTitle(scheduleList);
        }
      } else {
        scheduleList.sort((a: any, b: any) => {
          if (!a.allDay && !b.allDay) {
            // 시간별
            const aMoment = core.date.instance(a.startAt);
            const bMoment = core.date.instance(b.startAt);
            if (aMoment.isBefore(bMoment)) return -1;
            else if (aMoment.isAfter(bMoment)) return 1;
          }

          const aValue = core.utils.getValueFromObjectId(a, String(orderColumnName));
          const bValue = core.utils.getValueFromObjectId(b, String(orderColumnName));
          if (aValue != null && bValue != null) {
            if (order === "asc") {
              // 오름차순
              return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
              // 내림차순
              return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
            }
          } else {
            return -1;
          }
        });
        // scheduleList.length = 0;
        const fixedScheduleList = [] as any;
        const normalScheduleList = [] as any;
        scheduleList.forEach((schedule) => {
          if (schedule.topFixed && (schedule.userList == null || schedule.userList.length === 0)) {
            fixedScheduleList.push(schedule);
          } else {
            normalScheduleList.push(schedule);
          }
        });
        scheduleList.length = 0;
        //console.log("fixedScheduleList : ", fixedScheduleList);
        fixedScheduleList.forEach((schedule) => {
          scheduleList.push(schedule);
        });
        normalScheduleList.forEach((schedule) => {
          scheduleList.push(schedule);
        });
        //console.log("sort scheduleList ", scheduleList);
      }
    }
  }

  scheduleListSortedByTime(
    scheduleList: any,
    orderColumnName: string,
    order: string,
    startMoment: any,
    endMoment: any
  ) {
    const sortItems = {
      default: {
        day: [] as any,
        dawn: [] as any,
        morning: [] as any,
        afternoon: [] as any,
        evening: [] as any,
      },
      // as: {
      //   day: [] as any,
      //   dawn: [] as any,
      //   morning: [] as any,
      //   afternoon: [] as any,
      //   evening: [] as any,
      // },
      vacation: {
        day: [] as any,
        dawn: [] as any,
        morning: [] as any,
        afternoon: [] as any,
        evening: [] as any,
      },
      attendance: {
        day: [] as any,
        dawn: [] as any,
        morning: [] as any,
        afternoon: [] as any,
        evening: [] as any,
      },
    };

    for (let i = 0; i < scheduleList.length; i++) {
      const item = scheduleList[i];
      item.showMainTitle = false;
      delete item.mainTitle;
      item.showSubTitle = false;
      delete item.subTitle;

      let items = null as any;

      if (item.workType === "D") {
        items = sortItems.default;
      } else if (item.workType === "A") {
        items = sortItems.default;
      } else if (item.workType === "V") {
        items = sortItems.vacation;
      } else if (item.workType === "T") {
        items = sortItems.attendance;
      } else {
        console.log("Unknown workType : ", item.workType);
      }

      if (item.allDay) {
        item.type = "day";
        if (item.topFixed) {
          item.type = item.type + "_top";
        }
        items.day.push(item);
      } else if (!item.allDay) {
        let startAt = null as any;

        const endAtMoment = core.date.instance(item.endAt);
        // if (endAtMoment.format("HH:mm:ss") === "00:00:00") {
        //   // 만약 종료날짜가 검색 요청 일자와 동일하고, 0시 0분 0초 일경우 삭제 처리한다.
        //   if (startMoment.format("YYYY-MM-DD") === endAtMoment.format("YYYY-MM-DD")) {
        //     console.log(
        //       `delete schedule title '${item.title}', startAt '${item.startAt}', endAt '${item.endAt}'`
        //     );
        //     scheduleList.splice(i, 1);
        //     i--;
        //     continue;
        //   }
        // }

        const startAtDateMoment = core.date.instance(
          core.date.instance(item.startAt).format("YYYY-MM-DDT00:00:00")
        );
        if (startAtDateMoment.isBefore(startMoment)) {
          startAt = startMoment.format("YYYY-MM-DD") + "T00:00:00";
        } else {
          startAt = item.startAt;
        }
        item.viewStartAt = startAt;

        let endAt = null as any;

        const endAtDateMoment = core.date.instance(endAtMoment.format("YYYY-MM-DDT00:00:00"));
        if (endAtDateMoment.isAfter(startMoment)) {
          endAt = startMoment.format("YYYY-MM-DD") + "T00:00:00";
        } else {
          endAt = item.endAt;
        }
        item.viewEndAt = endAt;
        if (core.date.instance(startAt).hours() < 6) {
          item.type = "dawn";
          items.dawn.push(item);
        } else if (core.date.instance(startAt).hours() < 12) {
          item.type = "morning";
          items.morning.push(item);
        } else if (core.date.instance(startAt).hours() < 18) {
          item.type = "afternoon";
          items.afternoon.push(item);
        } else {
          item.type = "evening";
          items.evening.push(item);
        }
        if (item.topFixed) {
          item.type = item.type + "_top";
        }
      }
    }

    // const keys = ["default", "as", "vacation", "attendance"];
    const keys = ["default", "vacation", "attendance"];
    const subKeys = ["day", "dawn", "morning", "afternoon", "evening"];
    scheduleList.length = 0;
    keys.forEach((key) => {
      subKeys.forEach((subKey) => {
        const list = sortItems[key][subKey].sort((a: any, b: any) => {
          return this.sort(a, b, orderColumnName, order);
        });
        list.forEach((item) => {
          scheduleList.push(item);
        });
      });
    });
  }

  private sort(a: any, b: any, orderColumnName: string, order: string) {
    if (!a.allDay && !b.allDay) {
      // 시간별
      const aMoment = core.date.instance(a.startAt);
      const bMoment = core.date.instance(b.startAt);
      if (aMoment.isBefore(bMoment)) return -1;
      else if (aMoment.isAfter(bMoment)) return 1;
    }

    const aValue = core.utils.getValueFromObjectId(a, String(orderColumnName));
    const bValue = core.utils.getValueFromObjectId(b, String(orderColumnName));
    if (aValue != null && bValue != null) {
      if (order === "asc") {
        // 오름차순
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        // 내림차순
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    } else {
      return -1;
    }
  }

  updateScheduleTitle(scheduleList: any) {
    let type = "";
    scheduleList.forEach((schedule: any) => {
      this.convertSchedule(schedule);
      if (type != schedule.type) {
        type = schedule.type;
        schedule.showSubTitle = true;
        schedule.subTitle = "";
        // if (schedule.workType === "A") {
        //   schedule.subTitle += "A/S(";
        // }
        if (type === "day") {
          schedule.subTitle += "종일";
        } else if (type === "dawn") {
          schedule.subTitle += "새벽";
        } else if (type === "morning") {
          schedule.subTitle += "오전";
        } else if (type === "afternoon") {
          schedule.subTitle += "오후";
        } else if (type === "evening") {
          schedule.subTitle += "저녁";
        } else if (type === "day_top") {
          schedule.subTitle += "상단고정(종일)";
        } else if (type === "dawn_top") {
          schedule.subTitle += "상단고정(새벽)";
        } else if (type === "morning_top") {
          schedule.subTitle += "상단고정(오전)";
        } else if (type === "afternoon_top") {
          schedule.subTitle += "상단고정(오후)";
        } else if (type === "evening_top") {
          schedule.subTitle += "상단고정(저녁)";
        } else {
          schedule.subTitle += "알수없음";
        }

        // if (schedule.workType === "A") {
        //   schedule.subTitle += ")";
        // }
      } else {
        schedule.showSubTitle = false;
      }
    });
  }

  convertSchedule(schedule: any) {
    try {
      schedule.hasKerapoxy = false;
      schedule.hasMatte = false;
      schedule.hasAspactone = false;
      schedule.caption = "";
      schedule.captionView = "";
      if (schedule.category != null) {
        schedule.caption = `[${schedule.category.name}]\n`;
        schedule.captionView = `[${schedule.category.name}]\n`;
      }
      if (schedule.detailList != null) {
        let text = "";
        let html = "";
        let count = 1;
        schedule.detailList.forEach((scheduleDetail: any) => {
          if (core.utils.validate.isNotBlank(scheduleDetail.workScope)) {
            text += `${count}. ${scheduleDetail.workScope}\n`;
            if (scheduleDetail.workScope.indexOf("아스팍톤") > -1) {
              html += `<span class="purple--text">${count}. ${scheduleDetail.workScope}</span>\n`;
              schedule.hasAspactone = true;
            } else if (scheduleDetail.workScope.indexOf("케라폭시") > -1) {
              html += `<span class="blue--text">${count}. ${scheduleDetail.workScope}</span>\n`;
              schedule.hasKerapoxy = true;
            } else if (scheduleDetail.workScope.indexOf("무광") > -1) {
              html += `<span class="green--text">${count}. ${scheduleDetail.workScope}</span>\n`;
              schedule.hasMatte = true;
            } else {
              html += `${count}. ${scheduleDetail.workScope}\n`;
            }
            count++;
          }
        });
        if (text.length > 0) {
          text = text.substr(0, text.length - 1);
          schedule.caption += ` ${text}`;
        }

        if (html.length > 0) {
          html = html.substr(0, html.length - 1);
          schedule.captionView += ` ${html}`;
        }
      }

      schedule.captionView = core.utils.format.textToHtml(schedule.captionView);
      schedule.memoView = core.utils.format.textToHtml(schedule.memo);
    } catch (e) {
      console.log(e);
    }
  }

  sendPreVisitSms(scheduleId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/schedule/${scheduleId}/sms/pre-visit`, "")
        .then((data: any) => {
          if (data.smsPushId != null) {
            resolve(data.smsPushId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  sendPushMessage(scheduleId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/schedule/${scheduleId}/push`, "")
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  downloadExcel(params: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/work/schedule/excel`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  tempDownloadExcel(params: any) {
    // 임시 일정 조회
    const supportKeys = [
      "categoryId",
      "categoryIdList",
      "title",
      "titleList",
      "customerPhone",
      "customerPhoneList",
      "customerPhoneLike",
      "customerPhoneLikeList",
      "placeId",
      "placeIdList",
      "dong",
      "dongList",
      "ho",
      "hoList",
      "startDate",
      "endDate",
      "workType",
      "workTypeList",
      "teamId",
    ];
    const scheduleTempParams = {};
    for (const key of Object.keys(params)) {
      if (supportKeys.indexOf(key) > -1) {
        scheduleTempParams[key] = params[key];
      }
    }

    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/work/schedule/temp/excel`, null, scheduleTempParams)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createIssue(date: string, issue) {
    return new Promise((resolve: any, reject) => {
      const param = { date: date, issue: issue };
      const strParam = JSON.stringify(param);
      core.http
        .postJson(`/api/work/schedule/issue`, strParam)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  removeIssue(date: string) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/schedule/issue/${date}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getIssueList(startDate, endDate) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/issue`, null, { startDate: startDate, endDate: endDate })
        .then(async (data: any) => {
          resolve(data.list);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getAttendanceScheduleStatisticsList(date) {
    const params = {
      date: date,
    };
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/attendance/statistics`, null, params)
        .then(async (data: any) => {
          resolve(data.attendanceScheduleStatisticsList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ScheduleService();


import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import core from "@/core";
import UpdateEvent from "@/models";
import DownloadingModal from "@/modals/core/DownloadingModal.vue";
import EstimateUtils from "@/models/estimate";
import SupportService from "@/services/support/support.service";
import CommentService from "@/services/comment/comment.service";
import { EntityType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "SupportDownload",
  components: { DownloadingModal },

  data: () => ({
    title: "",

    dateTimeFormatPattern: "YYYY년 MM월 DD일 HH시 mm분",

    form: {
      placeIdList: [] as any,
      searchDates: [],
    },
    datePicker: {
      visible: false,
    },

    view: {
      placeName: "",
      searchDateText: "",
    },
    placeList: [] as any,

    // 선택된 아파트
    selectedPlace: null as any,

    placeTypeList: [] as any, // 선택된 아파트 타입 목록

    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeType: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      downloading: {
        visible: false,
        html: "데이터 다운로드를 시작합니다",
        callback: null as any,
      },
    },
    isDestroyed: false,
  }),
  created() {
    this.title = "고객지원 다운로드";

    const searchDates = this.form.searchDates as any;

    searchDates.push(core.date.instance().format("YYYY-MM-DD"));
    searchDates.push(core.date.instance().format("YYYY-MM-DD"));
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    });
  },
  computed: {
    today() {
      return core.date.instance().format("YYYY-MM-DD");
    },
    searchDateText() {
      const searchDates = this.form.searchDates as any;
      if (searchDates != null && searchDates.length > 1) {
        const moment1 = core.date.instance(searchDates[0]);
        const moment2 = core.date.instance(searchDates[1]);
        if (moment1.isAfter(moment2)) {
          const temp = searchDates[0];
          searchDates[0] = searchDates[1];
          searchDates[1] = temp;
        }
        return this.form.searchDates.join(" ~ ");
      }
      return "";
    },
  },
  watch: {
    "view.placeName"(val) {
      if (!this.isNotBlank(val)) {
        this.form.placeIdList = [];
      }
    },
  },
  destroyed() {
    this.isDestroyed = true;
  },
  methods: {
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    async submit() {
      // const params = {} as any;
      // core.loader.show("파일 생성중...");
      // console.log("this.form.placeIdList : ", this.form.placeIdList);
      // if (this.form.placeIdList != null && this.form.placeIdList.length > 0) {
      //   params.placeIdList = this.form.placeIdList.join(",");
      // }
      // if (this.form.searchDates != null && this.form.searchDates.length > 1) {
      //   params.createdAtGreaterThanEqual = this.form.searchDates[0];
      //   params.createdAtLessThan = core.date
      //     .instance(this.form.searchDates[1])
      //     .add(1, "days")
      //     .format("YYYY-MM-DD");
      // }
      //
      // try {
      //   console.log("params : ", params);
      //   const response = (await EstimateService.downloadExcel(params)) as any;
      //   // response.downloadUri;
      //   //console.log("response : ", response);
      //   core.loader.show("다운로드중...");
      //   await core.http.downloadFile(response.downloadUri);
      // } catch (e) {
      //   console.log(e);
      // }
      // core.loader.hide();
      this.exportCsv();
    },
    async exportCsv() {
      const items = {
        page: 1,
        perPage: 5000,
        searchColumns: {
          commentCountGreaterThan: 0,
        },
      } as any;

      // if (this.form.searchDates != null && this.form.searchDates.length > 1) {
      //   items.searchColumns.updatedAtGreaterThanEqual = this.form.searchDates[0];
      //   items.searchColumns.updatedAtLessThan = core.date
      //     .instance(this.form.searchDates[1])
      //     .add(1, "days")
      //     .format("YYYY-MM-DD");
      // } else {
      //   return;
      // }

      const modal = this.modal.downloading;
      modal.visible = true;
      modal.html = "고객지원 데이터 다운로드를 시작합니다.";

      const filename = `고객지원 데이터_${core.date.instance().format("YYYYMMDD_HHMM")}.csv`;
      // const titleList = ["시간", "온도", "습도", "메탄"];
      const csv = [] as any;
      let dataCount = 0;

      let running = true;
      let done = false;
      modal.callback = () => {
        console.log("cancel");
        running = false;
      };

      while (running) {
        if (this.isDestroyed) {
          break;
        }

        const params = {
          start: (items.page - 1) * items.perPage,
          length: items.perPage,
          orderColumnName: "updatedAt",
          order: "desc",
          searchColumns: items.searchColumns,
        } as any;
        modal.html = `고객지원 데이터를 다운로드 중입니다`;
        if (params.start > 0) {
          modal.html += `<br>${params.start}개 데이터 조회중...`;
        }

        // const startMoment = core.date.instance(this.startDate.date);
        // startMoment.hours(this.startDate.time);
        // params.startAt = startMoment.format("YYYY-MM-DD HH:00:00");
        //
        // const endMoment = core.date.instance(this.endDate.date);
        // endMoment.hours(this.endDate.time);
        // endMoment.add(1, "minutes");
        // params.endAt = endMoment.format("YYYY-MM-DD HH:00:00");

        const startMoment = core.date.instance(this.form.searchDates[0], true);
        const endMoment = core.date.instance(this.form.searchDates[1], true).add(1, "days");
        console.log(
          "searchDate : ",
          startMoment.format("YYYY-MM-DD HH:mm:ss"),
          endMoment.format("YYYY-MM-DD HH:mm:ss")
        );
        const tableResponse = (await SupportService.getTable(params)) as any;
        const supportList = tableResponse.data;
        let addCount = 0;
        const length = supportList.length;
        for (let i = 0; i < length; i++) {
          addCount++;

          const support = supportList[i];
          const updatedAtMoment = core.date.instance(support.updatedAt);
          if (
            updatedAtMoment.isSameOrAfter(startMoment) &&
            updatedAtMoment.isSameOrBefore(endMoment)
          ) {
            console.log("support : ", support);
            {
              const estimate = support.estimate;
              const title = EstimateUtils.titleToHtml(estimate);
              console.log("title : ", title);
              const row = [] as any;
              row.push(
                title +
                  ` (${startMoment.format("YYYY-MM-DD HH:mm:ss")} ~ ${endMoment.format(
                    "YYYY-MM-DD HH:mm:ss"
                  )})`
              );
              csv.push(row.join(","));
            }
            const commentList = (await CommentService.getList(
              EntityType.ESTIMATE_SUPPORT,
              support.estimateId
            )) as any;
            const commentLength = commentList.length;
            let exists = false;
            for (let j = 0; j < commentLength; j++) {
              const comment = commentList[j];
              const commentCreatedAtMoment = core.date.instance(comment.createdAt);
              if (
                commentCreatedAtMoment.isSameOrAfter(startMoment) &&
                commentCreatedAtMoment.isSameOrBefore(endMoment)
              ) {
                // console.log("comment : ", comment);
                {
                  const username = comment.user.name;
                  let msg = comment.content;
                  if (comment.type === "I") {
                    msg = "이미지";
                  } else if (comment.type === "L") {
                    const link = comment.link;
                    if (link != null) {
                      msg = link.content;
                      if (link.type === "I") {
                        msg = "이미지";
                      }
                    }
                  }
                  msg = msg.replaceAll(",", ".");
                  msg = msg.replaceAll("\n", ". ");

                  const row = [] as any;
                  row.push(this.dateTimeFormat(comment.createdAt), username, msg);
                  csv.push(row.join(","));
                  // addCount++;
                  exists = true;
                }
              }
            }
            csv.push("");
            if (exists) {
              dataCount++;
            }
          }
        }
        if (addCount === 0) {
          running = false;
          done = true;
          break;
        }
        items.page++;
      }

      if (!done) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: `지금까지 다운로드된 ${dataCount}개 데이터를 저장하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "저장",
        });
        if (confirmResult === "confirm") {
          done = true;
        }
      }

      if (done && dataCount > 0) {
        console.log("csv : ", csv);
        this.downloadCsv(csv.join("\n"), filename);
      } else {
        core.alert.show({
          title: "알림",
          body: `저장할 데이터가 없습니다`,
          confirmButtonText: "확인",
        });
      }

      modal.visible = false;
    },
    downloadCsv(csv, filename) {
      // 한글 처리를 해주기 위해 BOM 추가
      const BOM = "\uFEFF";
      csv = BOM + csv;

      const csvFile = new Blob([csv], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    elapsedTimeString(item) {
      let text = "";
      // dateTimeFormat(item.startAt) }} ~ {{ dateTimeFormat(item.endAt) }} ({{
      // elapsedTimeString(item)
      if (item.endAt) {
        const endMoment = core.date.instance(item.endAt);
        const startMoment = core.date.instance(item.startAt);
        //console.log("endMoment : ", endMoment.format("YYYY-MM-DD HH:mm:ss"));
        //console.log("startMoment : ", startMoment.format("YYYY-MM-DD HH:mm:ss"));
        const diffSec = endMoment.diff(startMoment, "seconds");
        if (diffSec < 60) {
          return `${diffSec}초`;
        }
        const diffMin = endMoment.diff(startMoment, "minutes");
        console.log("diffMin : ", diffMin);
        if (diffMin < 60) {
          return `${diffMin}분`;
        }

        const diffhour = endMoment.diff(startMoment, "hours");
        console.log("diffhour : ", diffhour);
        return `${diffhour}시간`;
      }
      return text;
    },
    elapsedTimeSec(item) {
      // dateTimeFormat(item.startAt) }} ~ {{ dateTimeFormat(item.endAt) }} ({{
      // elapsedTimeString(item)
      if (item.endAt) {
        const endMoment = core.date.instance(item.endAt);
        const startMoment = core.date.instance(item.startAt);
        //console.log("endMoment : ", endMoment.format("YYYY-MM-DD HH:mm:ss"));
        //console.log("startMoment : ", startMoment.format("YYYY-MM-DD HH:mm:ss"));
        const diffSec = endMoment.diff(startMoment, "seconds");

        return diffSec;
      }
      return "";
    },
  },
});

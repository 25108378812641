import core from "@/core";
import { TableRequest } from "@/types";

class UnitPriceGroupService {
  get(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/group/${id}`, null)
        .then((data: any) => {
          if (data.unitPrice != null) {
            resolve(data.unitPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getList(placeId: number) {
    const params = { placeId: placeId };
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/group`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/group/table`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`/api/unit-price/group`, strJson)
        .then((data: any) => {
          if (data.unitPrice != null) {
            resolve(data.unitPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  // update(id: number, params) {
  //   return new Promise((resolve, reject) => {
  //     const strJson = JSON.stringify(params);
  //     core.http
  //       .putJson(`/api/unit-price/group/${id}`, strJson)
  //       .then((data: any) => {
  //         if (data.unitPrice != null) {
  //           resolve(data.unitPrice);
  //         } else {
  //           reject({
  //             message: "not found item",
  //           });
  //         }
  //       })
  //       .catch((reason) => {
  //         reject(reason);
  //       });
  //   });
  // }

  delete(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/unit-price/group/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new UnitPriceGroupService();


import Vue from "vue";
import mixins from "vue-typed-mixins";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import store from "@/store";
import MixinsPage from "@/mixins/page";
import MixinsStorage from "@/mixins/single/storage";
import ScheduleUtils, { FilterScheduleViewType, ScheduleViewType } from "@/models/schedule";
import SupportAsService from "@/services/support/support-as.service";
import ScheduleVisitGuideModal from "@/modals/schedule/ScheduleVisitGuideModal.vue";
import { UserModel } from "@/models/user/user.model";
import { AppBarMenuItem, TableOrder, UpdateEventType, YnType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { CategoryModel } from "@/models/category/category.model";
import { cloneDeep } from "lodash";

export default mixins(MixinsPage, MixinsStorage, MixinsAppBarMenu).extend({
  name: "ScheduleComponent",
  components: { ScheduleVisitGuideModal },
  data: () => ({
    title: "일정",
    data: store.state.app.data,
    isVisibleTotalPriceSchedule: false,
    // 파라미터 준비
    paramReady: false,
    ready: false,
    storageItems: {
      type: "schedule-filter",
    },
    storageKey: "",
    calendar: {
      model: core.date.instance().format("YYYY-MM-DD"),
      title: "",
      items: [] as any,
      totalPrice: 0,
      dateMap: {} as any,
      reloadFlag: false,
      type: "month",
      viewTotalPrice: "",
    },
    viewType: ScheduleViewType.ALL,
    filterViewType: FilterScheduleViewType.ALL,
    scheduleViewType: "", // "" : 전체, "default" : 일반 일정만, "temp" : 입주예정일정만
    searchColumns: {} as any,
    defaultSearchColumns: {} as any, // 기본 검색 컬럼
    updatedType: "",
    visible: {
      attendanceSchedule: false,
      totalPrice: false,
      as: false,
    },
    datePicker: {
      visible: false,
      item: null as any,
      date: "",
    },
    notCompleteScheduleList: [] as any,
    asCount: 0,
    issueDateMap: {} as any,
    modal: {
      scheduleVisitGuide: {
        visible: false,
        params: {
          scheduleList: null as any,
        },
      },
    },
    attendanceScheduleStatisticsList: [] as any,
    myUser: {} as UserModel,

    ////////////
    windowResizeDate: null as Date | null,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      console.log("mounted");
      this.myUser = await this.$store.getters["auth/user"]();

      // 다운로드 버튼 관리자 이상만 활성화
      this.appBarChangeMenuVisible("download", this.isCompanyAdminRoleHigher);

      const params = core.utils.getUrlParams();
      const type = this.$route.params.type;
      if (type.indexOf("category") > -1) {
        const id = Number(type.split("-")[1]);
        params.viewType = ScheduleViewType.CATEGORY;
        params.categoryId = id;
        this.replaceState(params);
      } else if (type.indexOf("team") > -1) {
        const id = Number(type.split("-")[1]);
        params.viewType = ScheduleViewType.TEAM;
        params.teamId = id;
        this.replaceState(params);
      } else {
        params.viewType = type;
        this.replaceState(params);
      }
      if (params.month != null) {
        const moment = core.date.instance(params.month as string);
        if (moment.isValid()) {
          const date = moment.format("YYYY-MM-DD");
          if (date != this.calendar.model) {
            this.calendar.model = moment.format("YYYY-MM-01");
          }
        }
      }

      this.initSearchColumns(params);

      await this.storageWait();

      this.loadFilter();

      this.windowResize();

      // 금일 출근 여부 가져오기
      this.getTodayAttendanceSchedule();

      // 시공완료되지 않은 일정 조회
      this.getNotCompleteScheduleList();

      // 오늘 ~ 이틀 뒤까지 내 일정 중 고객에게 문자 전송되지 않은 목록 조회
      this.getCustomerPreVisibleSms();

      this.ready = true;
      this.calendar.reloadFlag = true;
      this.$store.dispatch("storage/setStorageItem", {
        type: "schedule-page",
        items: {
          type: type,
        },
      });
    });
  },
  async activated() {
    if (this.ready) {
      const items = core.utils.deepCopy(this.calendar.items) as any;
      if (items.length > 0) {
        this.calendar.items = [];
        items.forEach((item) => {
          this.calendar.items.push(item);
        });
        this.updatedType = "schedule";
      }

      this.getCustomerPreVisibleSms();

      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          const item = event.item;
          if (item != null) {
            this.initSearchColumns(item);
            this.loadFilter();
          }
          this.calendar.reloadFlag = true;
        } else if (event.result === UpdateEventType.FILTER) {
          this.updateSearchColumnsFromFilter(event.item);
          this.calendar.reloadFlag = true;
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    }
  },
  updated() {
    const updatedType = this.updatedType;
    this.updatedType = "";
    if (updatedType === "schedule") {
      this.windowResize();
    }
  },
  watch: {
    "datePicker.date"(date) {
      if (this.isNotBlank(date)) {
        this.updateScheduleDate(this.datePicker.item, date);
      }
      this.datePicker.item = null;
      this.datePicker.date = "";
    },
    "app.size"() {
      console.log("changed app.size : ", this.app.size);
      if (this.windowResizeDate == null) {
        this.windowResizeDate = new Date();
      }
      setTimeout(() => {
        if (this.windowResizeDate != null) {
          const elapsedTimeMillis = new Date().getTime() - this.windowResizeDate.getTime();
          if (elapsedTimeMillis > 500) {
            this.windowResizeDate = null;
            this.windowResize();
          }
        } else {
          console.log("ignore window resize event");
        }
      }, 500);
      // this.windowResize();
    },
    "calendar.reloadFlag"(reloadFlag) {
      this.calendar.reloadFlag = false;
      if (reloadFlag && this.ready) {
        this.loadSchedule();
      }
    },
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "download") {
        this.downloadExcel();
      } else if (menu.id === "filter") {
        const query = ScheduleUtils.getFilterColumns(this.viewType, this.searchColumns);
        // console.log("query : ", query);
        // const query = {} as any;
        query.viewType = this.viewType;
        // query.filterViewType = this.filterViewType;

        this.$router.push({
          path: `/schedule/${this.$route.params.type}/filter`,
          query: query,
        });
      } else if (menu.id === "search") {
        const query = ScheduleUtils.getUrlParamsFromMenu(this.defaultSearchColumns);
        query.viewType = this.viewType;
        this.$router.push({
          path: `/schedule/${this.$route.params.type}/search`,
          query: query,
        });
        // this.$router.push({ path: "/schedule/search", query: query });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    loadSchedule() {
      console.log("loadSchedule");
      const startMoment = core.date.instance(`${this.calendar.model}T00:00:00`);
      const strStartDate = startMoment.format("YYYY-MM-01");
      // 일정 정보 조회 및 그리기
      this.getScheduleList(this.searchColumns);

      // 관리자일 경우 출근부 가져오기
      if (this.isCompanyAdminRoleHigher) {
        this.getAttendanceScheduleStatisticsList(strStartDate);
      }
    },
    async getCustomerPreVisibleSms() {
      // 오늘 ~ 이틀 뒤까지 내 일정 중 고객에게 문자 전송되지 않은 목록 조회
      try {
        const searchColumns = {
          estimateIdIsNull: false,
          complete: false,
          startDate: core.date.instance().format("YYYY-MM-DD"),
          endDate: core.date.instance().add(3, "days").format("YYYY-MM-DD"),
          userId: this.myUser.id,
          sendVisitPreSmsYn: "N",
        };
        console.log("myUser.id, : ", this.myUser.id);
        console.log("searchColumns : ", searchColumns);

        const list = [] as any;
        {
          const userId = this.myUser.id;
          const _list = (await ScheduleService.getBySearchColumns(searchColumns, false)) as any;
          console.log("_list : ", _list);
          _list.forEach((item) => {
            if (item.userList) {
              let exists = false;
              item.userList.some((_user) => {
                if (_user.id === userId) {
                  exists = true;
                  return true;
                }
              });
              if (exists) {
                list.push(item);
              }
            }
          });
        }

        await ScheduleService.sortScheduleList(searchColumns, list, "startAt", "asc", "user");
        if (list.length > 0) {
          const modal = this.modal.scheduleVisitGuide;
          modal.params.scheduleList = list;
          modal.visible = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getNotCompleteScheduleList() {
      try {
        // 시공완료되지 않은 일정 조회
        const searchColumns = {
          estimateIdIsNull: false,
          complete: false,
          endAtLessThan: core.date.instance().format("YYYY-MM-DD 00:00:00"),
          userId: this.myUser.id,
        };

        this.notCompleteScheduleList = [];
        const notCompleteScheduleList = (await ScheduleService.getBySearchColumns(
          searchColumns,
          false
        )) as any;
        notCompleteScheduleList.forEach((data) => {
          if (data.userList != null) {
            data.userList.some((user) => {
              if (user.id == this.myUser.id) {
                this.notCompleteScheduleList.push(data);
                return true;
              }
            });
          }
        });

        // 팀 리더 일 경우 팀원 목록까지 가져옴
        if (this.myUser.company.teamList) {
          const teamIdList = [] as any;
          const map = {};
          this.notCompleteScheduleList.forEach((data) => {
            map[data.id] = data;
            data.visibleUserInfo = false;
          });
          for (const team of this.myUser.company.teamList) {
            //console.log("team : ", team);
            if (team.role === "LEADER") {
              teamIdList.push(team.id);

              const searchColumns = {
                estimateIdIsNull: false,
                complete: false,
                endAtLessThan: core.date.instance().format("YYYY-MM-DD 00:00:00"),
                teamId: team.id,
              };
              ScheduleService.getBySearchColumns(searchColumns, false).then((scheduleList: any) => {
                scheduleList.forEach((data) => {
                  if (!map[data.id]) {
                    data.visibleUserInfo = true;
                    this.notCompleteScheduleList.push(data);
                  }
                });
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTodayAttendanceSchedule() {
      // 금일 출근 여부 가져오기
      try {
        const user = this.myUser;
        if (user.company.attendanceYn === YnType.YES) {
          const today = core.date
            .instance(core.date.instance(), true)
            .format("YYYY-MM-DD 00:00:00");

          const tomorrow = core.date
            .instance(core.date.instance(), true)
            .add(1, "days")
            .format("YYYY-MM-DD 00:00:00");

          const searchColumns = {
            workType: "T",
            startAtGreaterThanEqual: today,
            startAtLessThan: tomorrow,
            userId: user.id,
          };

          const scheduleList = (await ScheduleService.getBySearchColumns(
            searchColumns,
            false
          )) as any;
          this.visible.attendanceSchedule = scheduleList.length === 0;
        } else {
          this.visible.attendanceSchedule = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getAttendanceScheduleStatisticsList(date) {
      try {
        this.attendanceScheduleStatisticsList =
          await ScheduleService.getAttendanceScheduleStatisticsList(date);
      } catch (e) {
        console.log(e);
      }
    },
    async submitAttendanceSchedule() {
      try {
        //console.log("출근 처리");
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "오늘 출근하셨나요?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        if (confirmResult === "confirm") {
          {
            const today = core.date
              .instance(core.date.instance(), true)
              .format("YYYY-MM-DD 00:00:00");
            const tomorrow = core.date
              .instance(core.date.instance(), true)
              .add(1, "days")
              .format("YYYY-MM-DD 00:00:00");

            const searchColumns = {
              workType: "T",
              startAtGreaterThanEqual: today,
              startAtLessThan: tomorrow,
              userId: this.myUser.id,
            };

            const scheduleList = (await ScheduleService.getBySearchColumns(
              searchColumns,
              false
            )) as any;
            // console.log("scheduleList : ", scheduleList);
            // const exists = (await AttendanceScheduleService.exists(today)) as any;
            this.visible.attendanceSchedule = scheduleList.length === 0;
          }
          if (this.visible.attendanceSchedule) {
            const today = core.date
              .instance(core.date.instance(), true)
              .format("YYYY-MM-DDT00:00:00");
            const user = this.myUser;
            const params = {
              title: `${user.name} 출근`,
              memo: "",
              workType: "T",
              allDay: true,
              startAt: today,
              endAt: today,
              color: "teal",
              topFixed: false,
              userList: [user.id],
            };
            const schedule = await ScheduleService.create(params);
            console.log("schedule : ", schedule);
            // const attendanceSchedule = await AttendanceScheduleService.create({ date: today });
            // console.log("attendanceSchedule : ", attendanceSchedule);
          }
          this.visible.attendanceSchedule = false;
          this.calendar.reloadFlag = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    moveAsPage() {
      console.log("move as page");
      this.$router.push({
        path: "/support-as",
        query: {
          userId: String(this.myUser.id),
          status: "RECEIPT",
        },
      });
    },
    async downloadExcel() {
      core.loader.show("파일 생성중...");
      try {
        const response = (await ScheduleService.downloadExcel(this.searchColumns)) as any;
        core.loader.show("다운로드중...");
        await core.http.downloadFile(response.downloadUri);
        core.loader.show("파일 생성중...");
        const responseTemp = (await ScheduleService.tempDownloadExcel(this.searchColumns)) as any;
        core.loader.show("다운로드중...");
        await core.http.downloadFile(responseTemp.downloadUri);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    getScheduleUserText(item) {
      const schedule = item;
      if (schedule.userList == null || schedule.userList.length === 0) return "없음";
      else {
        const mainUserNameList = [] as any;
        const subUserNameList = [] as any;
        schedule.userList.forEach((user: any) => {
          if (user.type === "MAIN") {
            mainUserNameList.push(user.name);
          } else {
            subUserNameList.push(user.name);
          }
        });
        return (
          mainUserNameList.join(",") +
          (subUserNameList.length > 0 ? "(" + subUserNameList.join(",") + ")" : "")
        );
      }
    },
    async updateScheduleDate(item, date) {
      const result = await core.alert.show({
        title: "확인",
        body: `${this.scheduleText(item)} 일정을 "${date}" 로 변경하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      });

      if (result === "confirm") {
        core.loader.show();
        try {
          const schedule = (await ScheduleService.get(item.id)) as any;
          const startMoment = core.date.instance(schedule.startAt);
          const newStartAt = core.date.instance(date + "T" + startMoment.format("HH:mm:ss"));
          const diffTimeMillis = newStartAt.toDate().getTime() - startMoment.toDate().getTime();
          const strStartAt = newStartAt.format("YYYY-MM-DDTHH:mm:ss");

          const endAt = core.date.instance(schedule.endAt);
          const newEndAt = endAt.add(diffTimeMillis / 1000, "seconds");
          const strEndAt = newEndAt.format("YYYY-MM-DDTHH:mm:ss");

          console.log(
            `change startAt ${schedule.startAt} -> ${strStartAt}, endAt ${schedule.endAt} -> ${strEndAt}`
          );

          const _schedule = await ScheduleService.updateDate(
            schedule.id,
            schedule.allDay,
            strStartAt,
            strEndAt
          );
          //console.log("_schedule : ", _schedule);
          const list = this.notCompleteScheduleList;
          for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
              list.splice(i, 1);
              break;
            }
          }

          this.calendar.reloadFlag = true;
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    async updateScheduleComplete(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `${this.scheduleText(item)} 일정 시공이 모두 완료되었나요?`,
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니오",
      });

      if (result === "confirm") {
        core.loader.show();
        try {
          const schedule = await ScheduleService.updateScheduleComplete(item.id, true);
          const list = this.notCompleteScheduleList;
          for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
              list.splice(i, 1);
              break;
            }
          }
          this.calendar.reloadFlag = true;
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    showEditScheduleModal(item) {
      this.datePicker.item = item;
      this.datePicker.visible = true;
    },
    showViewPage(scheduleId: number) {
      this.$router.push({ path: `/schedule/${scheduleId}`, query: { action: "status" } });
    },
    minDate() {
      return core.date.instance().format("YYYY-MM-DD");
    },
    scheduleText(item) {
      const date = this.scheduleDateText(item);

      let text = "";
      if (item.category != null) {
        text = `${item.category.name} (${date})`;
      } else {
        text = date;
      }

      if (item.workType === "A") {
        return `A/S ${text}`;
      }
      return text;
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
    initSearchColumns(item: any) {
      this.defaultSearchColumns = {} as any;
      {
        const preSearchColumns = this.searchColumns;
        this.searchColumns = {
          startDate: preSearchColumns.startDate,
          endDate: preSearchColumns.endDate,
        };
      }

      this.visible.totalPrice = false;

      this.appBarChangeMenuVisible("filter", true);
      this.appBarChangeMenuVisible("search", true);
      this.viewType = item.viewType;
      this.storageKey = ScheduleUtils.getStorageKey(item);
      this.defaultSearchColumns.workTypeList = "D,A";
      if (item.viewType == ScheduleViewType.ALL) {
        // 전체
        if (this.isCompanyAdminRoleHigher) {
          this.visible.totalPrice = true;
        }
        this.title = "전체";
      } else if (item.viewType == ScheduleViewType.DEFAULT) {
        // 기본
        this.defaultSearchColumns.estimateId = "null";
        this.title = "일반";
      } else if (item.viewType == ScheduleViewType.CATEGORY) {
        // 품목별
        if (item.categoryId == null) {
          console.log("not found categoryId, ", item.categoryId);
          return;
        }
        this.defaultSearchColumns.categoryId = item.categoryId;
        if (this.isCompanyAdminRoleHigher) {
          this.visible.totalPrice = true;
        }
        this.$store.getters["app/getCategoryList"]().then((categoryList: CategoryModel[]) => {
          categoryList.some((category) => {
            if (category.id == item.categoryId) {
              this.title = category.name;
              if (category.name == "줄눈") {
                this.isVisibleTotalPriceSchedule = true;
              }
              console.log("title : ", this.title);
              return true;
            }
          });
        });
      } else if (item.viewType == ScheduleViewType.TEAM) {
        // 팀별
        if (item.teamId == null) {
          console.log("not found teamId, ", item.teamId);
          return;
        }
        this.defaultSearchColumns.teamId = item.teamId;
        this.$store.getters["app/getTeamList"]().then((teamList: any) => {
          teamList.some((team) => {
            if (team.id == item.teamId) {
              if (team.category.name == "줄눈") {
                this.isVisibleTotalPriceSchedule = true;
              }
              this.title = `[${team.category.name}] ${team.name}`;
            }
          });
        });
        if (this.isCompanyAdminRoleHigher) {
          this.visible.totalPrice = true;
        }
      } else if (item.viewType == ScheduleViewType.VACATION) {
        delete this.defaultSearchColumns.workTypeList;
        this.defaultSearchColumns.workType = "V";
        this.title = "휴무";
        this.appBarChangeMenuVisible("download", false);
        this.appBarChangeMenuVisible("filter", true);
        this.appBarChangeMenuVisible("search", false);
      } else if (item.viewType == ScheduleViewType.ATTENDANCE) {
        delete this.defaultSearchColumns.workTypeList;
        this.defaultSearchColumns.workType = "T";
        this.title = "출근";
        this.appBarChangeMenuVisible("download", false);
        this.appBarChangeMenuVisible("filter", false);
        this.appBarChangeMenuVisible("search", false);
      } else {
        console.log("Unknown viewType : ", item.viewType);
        return;
      }
    },
    getSupportAsInfo() {
      // TODO : 어디서 호출할지 생각해봐야됨
      if (this.isCompanyUserRole) {
        const params = {
          draw: 0,
          start: 0,
          length: 1,
          orderColumnName: "createdAt",
          order: TableOrder.DESC,
          searchColumns: {
            activated: true,
            status: "RECEIPT",
          },
        };
        SupportAsService.getTable(params).then((result: any) => {
          console.log("result : ", result);
          this.asCount = result.recordsTotal;
          this.visible.as = this.asCount > 0;
        });
      } else {
        const params = {
          draw: 0,
          start: 0,
          length: 1,
          orderColumnName: "createdAt",
          order: TableOrder.DESC,
          searchColumns: {
            activated: true,
            status: "RECEIPT",
            userId: this.myUser.id,
          },
        };
        SupportAsService.getTable(params).then((result: any) => {
          console.log("result222 : ", result);
          this.asCount = result.recordsTotal;
          this.visible.as = this.asCount > 0;
        });
      }
    },
    loadFilter() {
      let item = this.getStorageItems(this.storageKey);
      if (item == null) {
        item = {};
        this.setStorageItem(this.storageKey, item);
      }

      if (item.placeId != null) {
        delete item.placeId;
        delete item.placeName;
      }

      console.log("item : ", item);
      this.updateSearchColumnsFromFilter(item);
    },
    updateSearchColumnsFromFilter(filters: any) {
      const item = core.utils.deepCopy(filters);
      console.log("filters : ", item);
      this.filterViewType = ScheduleUtils.getFilterViewType(this.viewType, item);

      const searchColumns = ScheduleUtils.getFilterColumns(this.viewType, item);
      //console.log("searchColumns : ", searchColumns);

      const defaultSearchColumns = this.defaultSearchColumns;
      // 기본 검색 컬럼 덮어쓰기
      for (const key of Object.keys(defaultSearchColumns)) {
        if (key === "workType" || key === "workTypeList") {
          if (this.isBlank(searchColumns.workType) && this.isBlank(searchColumns.workTypeList)) {
            searchColumns[key] = defaultSearchColumns[key];
          }
        } else {
          searchColumns[key] = defaultSearchColumns[key];
        }
      }
      //console.log("searchColumns : ", searchColumns);

      if (this.isNotBlank(this.searchColumns.startDate)) {
        searchColumns.startDate = this.searchColumns.startDate;
        searchColumns.endDate = this.searchColumns.endDate;
      } else {
        console.log("is not ready!!!!!!!!!");
        return;
      }

      const newItem = cloneDeep(item);
      if (this.storageKey === "vacation") {
        delete newItem.userName;
        delete newItem.viewType;
      }
      const itemKeys = Object.keys(newItem);
      // console.log("itemKeys : ", itemKeys);
      // console.log("storageKey : ", this.storageKey);
      let length = itemKeys.length;

      // if (ScheduleUtils.hasViewTypeFilter(this.viewType)) {
      //   if (this.filterViewType != FilterScheduleViewType.ALL) {
      //     length++;
      //   }
      // }

      // 필터 badge content 추가
      if (length > 0) {
        this.appBarChangeMenuBadge("filter", String(length));
      } else {
        this.appBarChangeMenuBadge("filter", "");
      }

      if (this.viewType == ScheduleViewType.DEFAULT) {
        // 일반 일정일 경우 estimateId: null 추가
      }
      this.searchColumns = searchColumns;
    },
    setToday() {
      this.calendar.model = "";
    },
    getMaxDaySize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 105;
      } else {
        if (this.app.size.width < 965) {
          offset = 110;
        } else {
          offset = 115;
        }
      }
      let height = this.app.size.height - offset - 610;
      // 610 - 6개
      if (height > 0) {
        return parseInt(String(height / 105)) + 9;
      } else {
        return 9;
      }
    },
    windowResize() {
      console.log("changed window size");
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 105;
      } else {
        if (this.app.size.width < 965) {
          offset = 110;
        } else {
          offset = 115;
        }
      }
      // this.calendar.height = this.app.size.height - offset;

      {
        const elList = document.querySelectorAll(".v-event-more-custom");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-issue");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-text");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      const elList = document.querySelectorAll(".v-calendar-weekly__week .v-calendar-weekly__day");
      if (elList != null) {
        const eventElList = document.querySelectorAll(
          ".v-calendar-weekly__week .v-calendar-weekly__day .v-event"
        );

        if (eventElList.length > 0) {
          eventElList.forEach((el: any) => {
            if (el.style.display == "none") {
              el.style.display = "block";
            }
          });
        }

        elList.forEach((el) => {
          if (el.clientHeight < el.scrollHeight) {
            const eventList = el.querySelectorAll("[data-date]");
            let height = 21; // label 제외
            let viewCount = 0;
            let addMoreCount = 0;
            let date = "";

            eventList.forEach((eventEl: any) => {
              if (eventEl.dataset.date != null) {
                date = eventEl.dataset.date;
              }
              height += eventEl.clientHeight;
              if (eventEl.classList.contains("v-event")) {
                height += 1;
              }
              if (height > el.clientHeight) {
                eventEl.style.display = "none";
                addMoreCount++;
              } else {
                viewCount++;
              }
            });
            if (this.issueDateMap[date]) {
              let moreEl = el.querySelector(".v-event-more-custom-issue");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom-issue");
                el.appendChild(moreEl);
              }
            }
            if (addMoreCount > 0) {
              //console.log("date : ", date, ", addMoreCount : ", addMoreCount);
              const list = this.calendar.dateMap[date];
              //console.log("list : ", list);
              addMoreCount = list.length - viewCount;
              //console.log("date : ", date);
              // this.calendar.dateMap[date];

              let moreEl = el.querySelector(".v-event-more-custom");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom");
                el.appendChild(moreEl);
              }
              let moreTextEl = el.querySelector(".v-event-more-custom-text");
              if (moreTextEl == null) {
                moreTextEl = document.createElement("div");
                moreTextEl.classList.add("v-event-more-custom-text");
                el.appendChild(moreTextEl);
              }
              moreTextEl.innerHTML = String(addMoreCount);
            }
          }
        });
      }
    },
    viewDay({ date }: any) {
      // this.showScheduleDayModal(date);
      const searchColumns: any = {};
      for (const key of Object.keys(this.searchColumns)) {
        if (key !== "startDate" && key !== "endDate") {
          const value = this.searchColumns[key];
          if (value != null) {
            searchColumns[key] = value;
          } else if (value === null) {
            searchColumns[key] = "null";
          }
        }
      }
      searchColumns.date = date;
      searchColumns.viewType = this.viewType;
      searchColumns.filterViewType = this.filterViewType;
      //console.log("searchColumns : ", searchColumns);
      this.$router.push({ path: "/schedule/day", query: searchColumns });
    },
    getEventColor(event: any) {
      return event.color;
    },
    getCalendarInstance() {
      return this.$refs.calendar as Vue & {
        prev: () => void;
        next: () => void;
        checkChange: () => void;
        getFormatter: (format: any) => any;
      };
    },
    prev() {
      this.getCalendarInstance().prev();
      this.scheduleReplaceState();
      // console.log("prev");
    },
    next() {
      this.getCalendarInstance().next();
      this.scheduleReplaceState();
      // console.log("next");
    },
    scheduleReplaceState() {
      const params = core.utils.getUrlParams();
      params.month = core.date.instance(this.calendar.model).format("YYYY-MM");
      const url = this.$route.path + "?" + core.http.objToUrlParams(params);
      history.replaceState("", "", url);
    },
    showEvent({ day, nativeEvent, event }: any) {
      const startMoment = core.date.instance(event.start);
      const endMoment = core.date.instance(event.end);
      // console.log(
      //   `start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}, end ${endMoment.format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   )}`
      // );
      const diff = startMoment.diff(endMoment, "days");
      if (diff === 0) {
        const searchColumns: any = {};
        for (const key of Object.keys(this.searchColumns)) {
          if (key !== "startDate" && key !== "endDate") {
            const value = this.searchColumns[key];
            if (value != null) {
              searchColumns[key] = value;
            } else if (value === null) {
              searchColumns[key] = "null";
            }
          }
        }
        searchColumns.date = startMoment.format("YYYY-MM-DD");
        searchColumns.viewType = this.viewType;
        searchColumns.filterViewType = this.filterViewType;
        //console.log("searchColumns : ", searchColumns);
        this.$router.push({ path: "/schedule/day", query: searchColumns });
      } else {
        const query = {
          viewType: this.viewType,
        };
        this.$router.push({ path: "/schedule/" + event.id, query: query });
      }
    },
    async updateRange({ start, end }: any) {
      //console.log("updateRange : ", start.date);

      const startMoment = core.date.instance(`${start.date}T00:00:00`);
      const strStartDate = startMoment.format("YYYY-MM-01");
      const endMoment = core.date.instance(`${start.date}T23:59:59`);

      // 타이틀 변경
      this.calendar.title = startMoment.format("YYYY년 MM월");

      //console.log(`start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}`);
      //console.log(`end ${endMoment.format("YYYY-MM-DD HH:mm:ss")}`);

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = startMoment.add(1, "months").format("YYYY-MM-DD");

      // this.$nextTick(() => {
      //   this.reloadFlag = true;
      // });

      console.log(
        `startDate : ${this.searchColumns.startDate}, endDate : ${this.searchColumns.endDate}`
      );

      // change range
      if (this.ready) {
        this.calendar.reloadFlag = true;
      }
    },
    swipe(direction: string) {
      //console.log("swipe : ", direction);
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    moneyFormat(value: string) {
      return core.utils.format.moneyKor(value);
    },
    async getScheduleList(searchColumns: any) {
      try {
        core.loader.show();
        // const items = [] as any;
        const items = (this.calendar.items = [] as any);

        let totalPrice = 0;

        const params = core.utils.deepCopy(searchColumns);
        // console.log("filterViewType : ", this.filterViewType);

        // 이슈 목록 조회
        this.issueDateMap = {};
        if (this.isCompanyCounselorRoleHigher) {
          const issueList = (await ScheduleService.getIssueList(
            params.startDate,
            params.endDate
          )) as any;
          issueList.forEach((issue) => {
            this.issueDateMap[issue.date] = true;
          });
        }

        // 일정 목록 조회
        const scheduleList = (await ScheduleService.getSimpleBySearchColumnsAndViewType(
          params,
          this.filterViewType
        )) as any;

        const startDate = new Date();
        if (scheduleList != null) {
          console.log("schedule size : ", scheduleList.length);

          // 날짜별 정렬
          const newScheduleList = [] as any;
          const totalPriceDateMap = {} as any;
          {
            const dateMap = {} as any;
            scheduleList.forEach((schedule) => {
              const startDateMoment = core.date.instance(schedule.startAt);
              const strDate = startDateMoment.format("YYYY-MM-DD");
              if (dateMap[strDate] == null) {
                dateMap[strDate] = [];
              }
              const list = dateMap[strDate];
              list.push(schedule);

              if (schedule.workType === "D") {
                totalPrice += schedule.totalPrice;
                // console.log("add totalPrice : ", schedule.totalPrice);
                // if (schedule.totalPrice < 0) {
                //   console.log("schedule : ", schedule);
                // }
              }

              if (this.isCompanyManagerRoleHigher && this.isVisibleTotalPriceSchedule) {
                const startDateMoment = core.date.instance(schedule.startAt, true);
                const strStartDate = startDateMoment.format("YYYY-MM-DD");
                const startDate = startDateMoment.toDate();
                if (totalPriceDateMap[strStartDate] == null) {
                  const totalPriceItem = {
                    id: null,
                    name: `총 금액`,
                    start: startDate,
                    end: startDate,
                    color: "blue",
                    timed: false,
                    price: 0,
                  };
                  totalPriceDateMap[strStartDate] = totalPriceItem;
                }

                const totalPriceItem = totalPriceDateMap[strStartDate];
                if (schedule.adjustPrice == null) schedule.adjustPrice = 0;
                totalPriceItem.price += schedule.totalPrice + schedule.adjustPrice;
              }
            });
            this.calendar.dateMap = dateMap;

            if (this.isCompanyManagerRoleHigher && this.isVisibleTotalPriceSchedule) {
              Object.keys(totalPriceDateMap).forEach((key) => {
                const totalPriceItem = totalPriceDateMap[key];
                totalPriceItem.name = `${totalPriceItem.price / 10000}만원`;
              });
            }

            const keyList = Object.keys(dateMap);
            keyList.forEach((strDate) => {
              const list = dateMap[strDate];
              const sortList = list.sort((a, b) => {
                const aStartDateMoment = core.date.instance(a.startAt);
                // const endDateMoment = core.date.instance(a.endAt, true);
                const bStartDateMoment = core.date.instance(b.startAt);
                if (aStartDateMoment.format("YYYYMMDD") !== bStartDateMoment.format("YYYYMMDD"))
                  return -1;
                else if (aStartDateMoment.isBefore(bStartDateMoment)) return -1;
                else if (bStartDateMoment.isBefore(aStartDateMoment)) return 1;
                return 0;
                // if (a.name > b.name) return 1;
                // else if (a.name < b.name) return -1;
                // return 0;
              });
              // console.log("sortList : ", sortList);
              let count = 0;
              const maxCount = this.getMaxDaySize();
              sortList.some((schedule) => {
                //console.log("startAt : ", schedule.startAt);
                newScheduleList.push(schedule);
                count++;
                if (count >= maxCount) {
                  return true;
                }
              });
            });
          }

          const completeScheduleList = [] as any;
          const paymentScheduleList = [] as any;

          const curMoment = core.date.instance();
          const ignoreScheduleUser = this.myUser.username === "user";

          // 날짜, 시간별 정렬
          newScheduleList.forEach((schedule: any) => {
            if (schedule.color.length == 0) schedule.color = "grey lighten-1";
            const title = ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, schedule);

            const startDateMoment = core.date.instance(schedule.startAt, true);
            if (ignoreScheduleUser) {
              const diffDays = startDateMoment.diff(curMoment, "days");
              if (diffDays > 7) {
                return;
              }
            }
            const startDate = startDateMoment.toDate();

            const strStartDate = startDateMoment.format("YYYY-MM-DD");
            const totalPriceItem = totalPriceDateMap[strStartDate];
            if (totalPriceItem != null) {
              // console.log("totalPriceItem : ", totalPriceItem);
              items.push(totalPriceItem);
              delete totalPriceDateMap[strStartDate];
            }

            let endDate = null as Date | null;
            {
              const endMoment = core.date.instance(schedule.endAt);
              if (schedule.allDay) {
                endDate = core.date.instance(endMoment, true).toDate();
              } else {
                const endDateMoment = core.date.instance(endMoment, true);
                const diffDays = endDateMoment.diff(startDate, "days");
                if (diffDays === 1 && endMoment.format("HH:mm:ss") === "00:00:00") {
                  endDate = core.date.instance(endMoment, true).add(-1, "days").toDate();
                } else {
                  endDate = core.date.instance(endMoment, true).toDate();
                }
              }
            }
            const color = ScheduleUtils.getScheduleColor(this.myUser, schedule);

            const item = {
              id: schedule.id,
              name: `${title}`,
              start: startDate,
              end: endDate,
              color: color,
              timed: false,
            };

            if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
              paymentScheduleList.push(item);
            } else if (schedule.complete) {
              completeScheduleList.push(item);
            } else {
              items.push(item);
            }
          });

          completeScheduleList.forEach((item) => {
            items.push(item);
          });
          paymentScheduleList.forEach((item) => {
            items.push(item);
          });
        }
        this.calendar.totalPrice = totalPrice;
        this.calendar.viewTotalPrice = this.moneyFormat(String(this.calendar.totalPrice));
        //console.log("this.calendar.viewTotalPrice : ", this.calendar.viewTotalPrice);
        this.updatedType = "schedule";
        const elapsedTimeMillis = new Date().getTime() - startDate.getTime();
        console.log("elapsedTimeMillis : ", elapsedTimeMillis);
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CommentComponent, { CommentProps, Mode } from "@/components/comment/CommentComponent.vue";
import ScheduleStatusComponent from "@/components/schedule/ScheduleStatusComponent.vue";
import ScheduleUserEditModal from "@/modals/schedule/ScheduleUserEditModal.vue";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import SmsService from "@/services/sms.service";
import ScheduleViewInfo from "@/components/schedule/ScheduleViewInfo.vue";
import ScheduleUtils from "@/models/schedule";
import EstimateService from "@/services/work/estimate.service";
import SendSmsScheduleCleaningModal from "@/modals/schedule/SendSmsScheduleCleaningModal.vue";
import ScheduleMemoEditModal from "@/modals/schedule/ScheduleMemoEditModal.vue";
import UpdateEvent from "@/models";
import { AppBarMenuItem, EntityType, UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { Style } from "@/store/modules/top-toolbar";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "ScheduleView",

  components: {
    ScheduleMemoEditModal,
    SendSmsScheduleCleaningModal,
    CommentComponent,
    ScheduleViewInfo,
    ScheduleStatusComponent,
    ScheduleUserEditModal,
  },

  data: () => ({
    title: "",
    toolbarHeight: 0,
    toolbarClass: "",
    menu: "",
    id: 0,
    tab: 0,
    first: true,
    schedule: {
      ready: false,
      category: {},
    } as any,
    modal: {
      memoEdit: {
        visible: false,
        params: {
          schedule: null,
        },
        updateEvent: null as UpdateEvent | null,
      },
      userEdit: {
        visible: false,
        params: {
          schedule: null,
          categoryId: null,
          teamId: null,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      smsScheduleCleaning: {
        visible: false,
        params: {
          schedule: null,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    component: {
      scheduleStatus: {
        updateEvent: null as any as UpdateEvent | null,
      },
      scheduleEntity: {
        visible: false,
        mode: Mode.DEFAULT,
        type: EntityType.SCHEDULE,
        id: 0,
        idList: [],
        systemMessageList: [],
        reloadFlag: false,
      } as CommentProps,
      estimateEntity: {
        visible: false,
        mode: Mode.DEFAULT,
        type: EntityType.ESTIMATE,
        id: 0,
        idList: [],
        systemMessageList: [],
        reloadFlag: false,
      },
    },
    updateEvent: null as any as UpdateEvent | null,
    visible: {
      menu: true,
      estimate: false,
      user: false,
      delete: false,
      smsSchedule: false,
      smsScheduleCleaning: false,
      complete: false,
    },
  }),

  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  created() {
    // 생성 후 호출
    const params = this.$route.params as any;
    this.id = params.id as number;
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      await this.redraw();
      this.changedTopButtons();

      this.checkMySchedule();
    });
  },

  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.RELOAD || event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("/status") !== -1) {
          // 시공현황 에서 이벤트 발생했을 경우 이벤트 전송
          this.component.scheduleStatus.updateEvent = event;
        } else {
          await this.redraw();
          // 이전 페이지 이벤트 전송
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        }
      } else if (event.result === UpdateEventType.DELETE) {
        this.goBack(event.result, event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }

    this.checkMySchedule();
  },
  watch: {
    tab(tab) {
      [this.component.estimateEntity, this.component.scheduleEntity].forEach((entity) => {
        entity.visible = false;
      });
      const schedule = this.schedule;
      if (schedule != null) {
        if (schedule.estimateId != null) {
          if (tab === 0) {
            const entity = this.component.estimateEntity;
            entity.visible = true;
          } else if (tab === 1) {
            const entity = this.component.scheduleEntity;
            entity.visible = true;
          } else {
            console.log("invalid tab : ", tab);
          }
        } else {
          if (tab === 0) {
            const entity = this.component.scheduleEntity;
            entity.visible = true;
          } else {
            console.log("invalid tab : ", tab);
          }
        }
      } else {
        console.log("not found schedule");
      }
    },
    async schedule(schedule) {
      if (
        schedule != null &&
        schedule.estimateId != null &&
        schedule.userList != null &&
        schedule.userList.length > 0
      ) {
        const curDate = core.date.instance(core.date.instance().format("YYYY-MM-DDT00:00:00"));
        const startDate = core.date.instance(
          core.date.instance(schedule.startAt).format("YYYY-MM-DDT00:00:00")
        );
        const diffDays = startDate.diff(curDate, "days");
        if (0 <= diffDays && diffDays <= 7) {
          this.visible.smsSchedule = true;
        }

        if (schedule.category.name == "청소") {
          this.visible.smsScheduleCleaning = true;
        } else {
          this.visible.smsScheduleCleaning = false;
        }
      }

      if (schedule != null && schedule.estimateId != null) {
        if (ScheduleUtils.isStartAtBeforeToday(this.schedule)) {
          this.visible.complete = true;
        }
      }
    },
    "modal.userEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.userEdit);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.UPDATE) {
          // this.component.scheduleEntity.id = event.item.id;
          // this.component.estimateEntity.id = event.item.estimateId;
          // this.schedule = event.item;
          // this.schedule.ready = true;
          this.redraw();
          // 이전 페이지 이벤트 전송
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },

    "modal.memoEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.memoEdit);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.UPDATE) {
          this.redraw();
          // 이전 페이지 이벤트 전송
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },

  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "topFixedTrue") {
        this.changeTopFixed(true);
      } else if (menu.id === "topFixedFalse") {
        this.changeTopFixed(false);
      } else if (menu.id === "estimate") {
        this.showEstimateViewPage();
      } else if (menu.id === "edit") {
        this.showScheduleEditPage();
      } else if (menu.id === "editMemo") {
        this.showMemoEditModal();
      } else if (menu.id === "delete") {
        this.deleteSchedule();
      } else if (menu.id === "addAs") {
        this.showAddAsPage();
      } else if (menu.id === "sendSmsSchedule") {
        this.sendSmsSchedule();
      } else if (menu.id === "editUser") {
        this.showUserEditModal();
      } else if (menu.id === "cancelScheduleComplete") {
        this.updateScheduleComplete(false);
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    changedTopButtons() {
      const schedule = this.schedule;
      if (schedule) {
        this.appBarChangeMenuVisible("topFixedTrue", !schedule.topFixed);
        this.appBarChangeMenuVisible("topFixedFalse", schedule.topFixed);

        const visible = this.visible;
        this.appBarChangeMenuVisible("estimate", visible.estimate);
        this.appBarChangeMenuVisible("edit", true);
        this.appBarChangeMenuVisible("editMemo", true);
        this.appBarChangeMenuVisible("delete", visible.delete);
        this.appBarChangeMenuVisible("addAs", schedule.estimateId != null);
        this.appBarChangeMenuVisible("sendSmsSchedule", visible.smsSchedule);
        this.appBarChangeMenuVisible("editUser", visible.user);
        this.appBarChangeMenuVisible("cancelScheduleComplete", schedule.complete);
      }
    },
    async checkMySchedule() {
      console.log("check my schedule");
      const schedule = this.schedule;
      if (!schedule.ready) {
        return;
      }
      const user = this.myUser;
      let isMySchedule = false;
      let isSubUser = false;
      if (schedule.userList != null) {
        schedule.userList.forEach((_user) => {
          console.log("user : ", _user);
          if (user.id == _user.id) {
            isMySchedule = true;
            if (_user.type === "SUB") {
              isSubUser = true;
            }
          }
        });
      }

      if (isMySchedule) {
        if (isSubUser && this.isCompanyUserRole) {
          this.visible.menu = false;
        }

        if (schedule.sendVisitPreSmsYn === "N") {
          const curDate = core.date.instance(core.date.instance().format("YYYY-MM-DD 00:00:00"));
          const startAtMoment = core.date.instance(
            core.date.instance(schedule.startAt).format("YYYY-MM-DD 00:00:00")
          );
          const diffDay = startAtMoment.diff(curDate, "days");
          console.log("diffDay : ", diffDay);
          if (0 < diffDay && diffDay < 3) {
            console.log("알림 표시");
            if (schedule.allDay) {
              const result = await core.alert.show({
                title: "알림",
                body: `방문 예정 ${diffDay}일 전 입니다.<br>시간 설정 후 고객에게 일정 문자를 전송해주세요!`,
                showCancelButton: true,
                cancelButtonText: "닫기",
                confirmButtonText: "설정",
              });
              if (result === "confirm") {
                this.showScheduleEditPage();
              }
            } else {
              //console.log("schedule : ", schedule);
              const result = await core.alert.show({
                title: "알림",
                body: `방문 예정 ${diffDay}일 전 입니다.<br>고객에게 일정 문자를 전송하시겠습니까?`,
                showCancelButton: true,
                cancelButtonText: "닫기",
                confirmButtonText: "전송",
              });
              if (result === "confirm") {
                await this.sendSmsSchedule(true);
              }
            }
          }
        }
      }
    },
    showMemoEditModal() {
      const modal = this.modal.memoEdit;
      modal.params.schedule = this.schedule;
      modal.visible = true;
    },
    async changeTopFixed(topFixed) {
      try {
        core.loader.show();
        const schedule = await ScheduleService.updateScheduleTopFixed(this.schedule.id, topFixed);
        core.utils.copy(schedule, this.schedule);
        this.changedTopButtons();
        // 이전 페이지 이벤트 전송
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async sendPushMessage() {
      try {
        await ScheduleService.sendPushMessage(this.schedule.id);
        core.alert.show({ title: "알림", body: "메시지가 전송되었습니다." });
      } catch (e) {
        console.log(e);
      }
    },
    async updateScheduleComplete(complete: boolean) {
      try {
        if (this.schedule.complete) {
          const result = await core.alert.show({
            title: "확인",
            body: "시공이 <span class='green--text'>완료</span>된 상태입니다.<br>시공 완료를 <span class='red--text'>취소</span>하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "예",
            cancelButtonText: "아니오",
          });
          if (result !== "confirm") {
            return;
          }
        }

        core.loader.show();
        const schedule = await ScheduleService.updateScheduleComplete(this.schedule.id, complete);
        core.utils.copy(schedule, this.schedule);
        // 이전 페이지 이벤트 전송
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    showAddAsPage() {
      const schedule = this.schedule;

      const query = {} as any;

      if (this.isNotBlank(this.$route.query.viewType)) {
        query.viewType = this.$route.query.viewType;
      }

      if (this.isNotBlank(schedule.estimateId)) {
        query.estimateId = schedule.estimateId;
      }

      if (schedule.category != null) {
        query.categoryId = schedule.category.id;
      }

      if (this.isNotBlank(schedule.teamId)) {
        query.teamId = schedule.teamId;
      }

      this.$router.push({
        path: `/schedule/as`,
        query: query,
      });
    },
    async sendSmsScheduleCleaning() {
      const modal = this.modal.smsScheduleCleaning;
      modal.params.schedule = this.schedule;
      modal.visible = true;
    },
    async sendSmsSchedule(ignoreAlert?) {
      let result = "" as any;
      if (ignoreAlert == null || !ignoreAlert) {
        result = await core.alert.show({
          title: "확인",
          body: `현재 일정 및 담당자 정보를 고객에게 전송하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
      } else {
        result = "confirm";
      }

      if (result === "confirm") {
        try {
          const redraw = this.redraw;
          core.loader.show("문자 전송중...");
          const smsPushId = await ScheduleService.sendPreVisitSms(this.schedule.id);
          const interval = setInterval(async () => {
            try {
              const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
              if (apiResult.result) {
                clearInterval(interval);
                core.loader.hide();
                redraw();
                await core.alert.show({
                  title: "알림",
                  body: "문자 전송 완료",
                });
              } else if (apiResult.code < 0) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: apiResult.message,
                });
              }
            } catch (e) {
              console.log(e);
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async redraw() {
      try {
        core.loader.show();
        const schedule = (await ScheduleService.get(this.id)) as any;
        // console.log("schedule : ", schedule);

        this.component.scheduleEntity.id = schedule.id;

        if (schedule.estimateId != null) {
          this.toolbarClass = schedule.estimate.caution ? "caution" : "";
          if (schedule.estimate.caution) {
            this.$store.dispatch("topToolbar/changeStyle", {
              dark: true,
              color: "red",
              clazz: "",
            } as Style);
          } else {
            this.$store.dispatch("topToolbar/changeOrgStyle");
          }
          //console.log("toolbarClass : ", this.toolbarClass);
          const scheduleList = (await EstimateService.getScheduleList(schedule.estimateId)) as any;
          const idList = [] as any;
          scheduleList.forEach((_schedule) => {
            if (_schedule.category.id === schedule.category.id) {
              idList.push(_schedule.id);
            }
          });
          this.component.scheduleEntity.idList = idList;

          this.component.estimateEntity.id = schedule.estimateId;
          if (this.first) {
            this.first = false;

            const action = this.$route.query.action;
            if (action === "status") {
              this.tab = 2;
            } else {
              this.tab = 1;
            }
          }
        } else {
          this.component.scheduleEntity.visible = true;
        }

        this.schedule = schedule;

        this.schedule.ready = true;
        if (schedule.category != null) {
          this.title = `[${schedule.category.name}] ${schedule.title}`;
        } else {
          this.title = `${schedule.title}`;
        }
        // 견적정보에 포함된 일정일 경우 A/S 일정만 삭제 버튼 표시
        if (this.schedule.estimateId != null) {
          if (this.schedule.workType === "A") {
            this.visible.delete = true;
          }
        } else {
          this.visible.delete = true;
        }

        if (schedule.estimateId != null) {
          this.visible.estimate = true;
          const myUser = this.myUser;
          if (this.isCompanyCounselorRoleHigher) {
            this.visible.user = true;
          } else {
            if (myUser.company.teamList != null) {
              for (const team of myUser.company.teamList) {
                if (team.role === "LEADER") {
                  if (schedule.teamId == team.id) {
                    this.visible.user = true;
                  }
                }
              }
            }
          }
        } else {
          this.visible.estimate = false;
          this.visible.user = false;
        }
      } catch (e) {
        core.loader.hide();
        await this.notFound();
      } finally {
        core.loader.hide();
      }
    },
    showScheduleEditPage() {
      const query = { id: this.schedule.id } as any;
      if (this.isNotBlank(this.$route.query.viewType)) {
        query.viewType = this.$route.query.viewType;
      }
      this.$router.push({
        path: "/schedule/edit",
        query: query,
      });
    },
    showEstimateViewPage() {
      //console.log(this.schedule);
      // const modal = this.modal.estimateViewDetail;
      // modal.params.estimateId = this.schedule.estimateId;
      // modal.visible = true;
      this.$router.push({
        path: `/estimate/${this.schedule.estimateId}`,
      });
    },
    showUserEditModal() {
      const modal = this.modal.userEdit;
      modal.params.schedule = this.schedule;

      const query = {} as any;
      if (this.isNotBlank(this.$route.query.viewType)) {
        query.viewType = this.$route.query.viewType;
      }

      modal.visible = true;
    },
    async deleteSchedule() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 일정을 삭제하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          await ScheduleService.delete(this.schedule.id);
          this.goBack(UpdateEventType.RELOAD);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async updateScheduleColor(item: any) {
      core.loader.show();
      try {
        const schedule: any = await ScheduleService.updateColor(this.schedule.id, item.color);
        schedule.ready = true;

        this.schedule = schedule;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
  },
});

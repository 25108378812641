
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import ColorItems from "@/data/color-items.js";
import ScheduleUtils, { ScheduleViewType } from "@/models/schedule";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";
import MixinsComponent from "@/mixins/component";
import CompanyService from "@/services/company/company.service";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    title: "제목",
    startAt: "시작일정",
    endAt: "종료일정",
    date: "일정",
    categoryId: "품목",
    user: "담당자",
    team: "팀",
  },
});

export default mixins(MixinsPageForm, MixinsComponent, MixinsStorage).extend({
  name: "ScheduleVacationAddEditComponent",

  data: () => ({
    storageItems: {
      type: "schedule-add-edit",
    },
    app: store.state.app,
    type: "",
    ready: false,
    processing: false,
    form: {
      title: "",
      memo: "",
      workType: "V",
      allDay: true,
      startAt: "",
      endAt: "",
      color: "teal",
      topFixed: false,
    },
    colorItems: ColorItems,

    schedule: null as any,
    viewType: ScheduleViewType.ALL,
    visible: {
      delete: false, // 삭제 버튼 표시 여부
      user: false, // 담당자 표시 여부
    },

    modal: {
      timepicker: {
        start: "",
        visible: false,
        params: {
          time: "",
          type: "",
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as any as UpdateEvent | null,
      },
      user: {
        visible: false,
      },
    },
    user: {
      map: new Map(),
      filterList: [] as any,
      text: null as string | null,
      selected: null as any,
    },
  }),

  async mounted() {
    this.$nextTick(async () => {
      await this.storageWait();
      const items = this.getStorageItems();
      if (items.userColorThemes == null) {
        items.userColorThemes = {};
        this.saveStorageItems();
      }
      const params = this.$route.params;
      const query = this.$route.query as any;
      let viewType = ScheduleUtils.getViewType(query.viewType);
      if (viewType == null) {
        if (this.isBlank(query.estimateId)) {
          viewType = ScheduleViewType.ALL;
        } else {
          viewType = ScheduleViewType.ESTIMATE;
        }
      }
      this.viewType = viewType;

      const type = (this.type = params.type as string);
      if (query.startDate != null) {
        this.form.startAt = core.date
          .instance(query.startDate as string)
          .format("YYYY-MM-DDT00:00:00");
      }

      if (query.endDate != null) {
        this.form.endAt = core.date.instance(query.endDate as string).format("YYYY-MM-DDT00:00:00");
      }

      if (type === "add") {
        if (this.isCompanyCounselorRoleHigher) {
          this.visible.user = true;

          const list = (await CompanyService.getUserList({
            lastApprovalType: "ALLOW",
          })) as any;
          list.forEach((user) => {
            this.user.map.set(user.id, user);
            this.user.filterList.push(user);
          });
        } else {
          this.visible.user = false;
          this.user.selected = this.myUser;
        }
      } else if (type === "edit") {
        const scheduleId = Number(query.id);
        if (scheduleId <= 0) {
          await this.notFound();
          return;
        }

        try {
          await this.loadSchedule(scheduleId);
        } catch (e) {
          await this.goBack();
          return;
        }

        this.visible.delete = true;

        const schedule = this.schedule;
        const form = this.form;
        for (const key of Object.keys(form)) {
          const value = schedule?.[key];
          if (value != null) {
            form[key] = value;
            //console.log(`key : ${key}, value : ${value}`);
          }
        }
        if (this.schedule.userList != null && this.schedule.userList.length > 0) {
          this.user.selected = this.schedule.userList[0];
        }
      } else {
        await this.notFound();
        return;
      }

      this.ready = true;
    });
  },

  watch: {
    "form.title"(title) {
      this.changeValue();
    },
    "form.memo"() {
      this.changeValue();
    },
    "form.startAt"(val, prev) {
      this.changeValue();
    },
    "form.endAt"(val, prev) {
      this.changeValue();
    },
    "user.selected"(val) {
      //console.log("user main selected : ", val);
      if (val != null) {
        this.user.text = val.name;
        if (this.type === "add") {
          this.form.title = `${val.name} 휴무`;
        }
        if (val.company && val.company.options && val.company.options.scheduleColor) {
          this.form.color = val.company.options.scheduleColor;
        }
      } else {
        this.user.text = "";
      }
    },
    "modal.timepicker.updateEvent"(val) {
      const event = this.getComponentUpdateEvent(this.modal.timepicker);
      if (event != null && event.result == UpdateEventType.CONFIRM) {
        const item = event.item;
        if (item.type === "startAt") {
          const prev = this.form.startAt;
          const startAt =
            core.date.instance(this.form.startAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(startAt, this.form.endAt, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else if (item.type === "endAt") {
          const prev = this.form.endAt;
          const endAt =
            core.date.instance(this.form.endAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else {
          console.log("Unknown item, ", item);
        }
      }
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          if (item.returnType === "startAt") {
            const prev = this.form.startAt;
            const startAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(startAt, this.form.endAt, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else if (item.returnType === "endAt") {
            const prev = this.form.endAt;
            const endAt = item.date + core.date.instance(prev).format("THH:mm:ss");
            const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
            this.form.startAt = calendar.startAt;
            this.form.endAt = calendar.endAt;
          } else {
            console.log("unknown returnType : ", item.returnType);
          }
        }
      }
    },
  },
  methods: {
    async loadSchedule(scheduleId) {
      this.schedule = (await ScheduleService.get(scheduleId)) as any;
      return this.schedule;
    },
    mapToList(map) {
      const list = [] as any;
      map.forEach((item) => {
        list.push(item);
      });
      return list;
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년MM월DD일(ddd)");
    },
    getTime(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("a hh:mm");
    },
    clickAllDay() {
      this.form.allDay = !this.form.allDay;

      this.changeValue();

      this.changedAllDay();
    },
    changedAllDay() {
      const allDay = this.form.allDay;
      if (!allDay) {
        const sec = core.date
          .instance(this.form.endAt)
          .diff(core.date.instance(this.form.startAt), "seconds");
        if (sec == 0) {
          const startHour = core.date.instance().add(1, "hours").format("HH");
          const endHour = core.date.instance().add(2, "hours").format("HH");
          this.form.startAt = core.date
            .instance(this.form.startAt)
            .format("YYYY-MM-DDT" + startHour + ":00:00");
          this.form.endAt = core.date
            .instance(this.form.endAt)
            .format("YYYY-MM-DDT" + endHour + ":00:00");
        }
        console.log(`change allday, startAt ${this.form.startAt}, endAt ${this.form.endAt}`);
      }
    },
    showScheduleSelectPage(returnType: string, date: string) {
      const moment = core.date.instance(date);
      const query = {
        month: moment.format("YYYY-MM"),
        returnType: returnType,
        viewType: this.viewType,
      } as any;

      this.$router.push({
        path: `/schedule/select`,
        query: query,
      });
    },
    showScheduleTimeModal(type: string, at: string) {
      const moment = core.date.instance(at);
      const modal = this.modal.timepicker;
      modal.params.time = moment.format("HH:mm");
      modal.params.type = type;
      modal.start = "";
      if (type === "startAt" && this.schedule != null && this.schedule.estimateId != null) {
        modal.start = "08:00";
      } else if (type === "endAt") {
        const startAtMoment = core.date.instance(this.form.startAt);
        const endAtMoment = core.date.instance(this.form.endAt);
        const strStartAt = startAtMoment.format("YYYYMMDD");
        const strEndAt = endAtMoment.format("YYYYMMDD");
        //console.log(`strStartAt : ${strStartAt}, strEndAt : ${strEndAt}`);
        if (strStartAt === strEndAt) {
          modal.start = core.date.instance(this.form.startAt).format("HH:mm");
        }
      }
      modal.visible = true;
    },
    changeValue(changed?: boolean) {
      if (changed != null) {
        this.changeBackButton(changed);
      } else {
        this.changeBackButton(true);
      }
    },
    async deleteItem() {
      const schedule = this.schedule;
      if (schedule != null) {
        const result = await core.alert.show({
          title: "확인",
          body: "현재 일정을 삭제하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });

        if (result === "confirm") {
          try {
            await ScheduleService.delete(schedule.id);
            this.goBack(UpdateEventType.DELETE, schedule.id);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    async submit() {
      console.log("submit");
      if (await this.validate()) {
        core.loader.show();
        try {
          const params = core.utils.deepCopy(this.form);

          if (this.user.selected != null) {
            params.userList = [this.user.selected.id];
          }

          let schedule = null as any;
          if (this.type === "add") {
            schedule = await ScheduleService.create(params);
          } else if (this.type === "as") {
            schedule = await ScheduleService.create(params);
          } else if (this.type === "edit") {
            const orgSchedule = this.schedule;
            schedule = await ScheduleService.update(orgSchedule.id, params);
          } else {
            console.log("unknown type : ", this.type);
          }

          if (schedule != null) {
            console.log(this.type + ", schedule : ", schedule);
            // const items = this.getStorageItems();
            // items.userColorThemes[schedule] = schedule.teamId;
            // this.saveStorageItems();
          }

          this.goBack(UpdateEventType.RELOAD, schedule);
        } catch (e) {
          this.errorSubmit(e);
        }
        core.loader.hide();
      }
    },
  },
});

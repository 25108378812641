
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import CategoryService from "@/services/work/category.service";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { cloneDeep } from "lodash";
import NoticeService from "@/services/notice/notice.service";
import UpdateEvent from "@/models";
import CompanyService from "@/services/company/company.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    content: "공지내용",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "NoticeAddEditModal",
  data: () => ({
    type: "",
    form: {
      content: "",
    },
    processing: false,
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;
    if (this.type !== "add" && this.type !== "edit") {
      await this.notFound();
    }

    if (this.type === "edit") {
      if (properties.params.item == null || properties.params.item.id == null) {
        await this.notFound();
      }
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (this.type === "edit") {
        const properties = this.properties;
        const item = properties.params.item;
        this.form.content = item.content;
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const form = cloneDeep(this.form) as any;
          const type = this.type;
          let item = null as any;

          const userList = (await CompanyService.getUserList({
            lastApprovalType: "ALLOW",
            companyRoleList: "COUNSELOR,MANAGER,ADMIN,OWNER",
          })) as any;
          console.log("userList : ", userList);
          form.noticeUserList = [];
          userList.forEach((user) => {
            form.noticeUserList.push(user.id);
          });
          if (type === "add") {
            item = await NoticeService.create(form);
            console.log("create item : ", item);
          } else if (type === "edit") {
            const params = this.properties.params;
            item = await NoticeService.update(params.item.id, form);
            console.log("update item : ", item);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          core.loader.hide();
          this.close(UpdateEventType.UPDATE, item);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await NoticeService.delete(item.id);
          console.log("result : ", result);
          core.loader.hide();
          this.close(UpdateEventType.DELETE, item);
        } catch (e) {
          console.log(e);
          this.cancel();
        }
      }
    },
  },
});

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./registerServiceWorker";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

// import Viewer from "v-viewer";
import TimePickerModal from "@/modals/core/TimePickerModal.vue";
import { extend } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import Constant from "@/store/constant";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import TopToolbar from "@/components/core/TopToolbar.vue";
import ComponentTemplate from "@/components/core/ComponentTemplate.vue";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// import FilerobotImageEditor from "@/assets/js/filerobot-image-editor.min.js";
// console.log("FilerobotImageEditor : ", FilerobotImageEditor);

const userAgent = navigator.userAgent.toLowerCase();
const isApp = userAgent.indexOf("scc") > -1;
if (!isApp) {
  try {
    // https://firebase.google.com/docs/web/setup#available-libraries
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyB1-5w6m11m8hsLn_1c6j7abIRzYGkYR1M",
      authDomain: "smartline-992d8.firebaseapp.com",
      databaseURL: "https://smartline-992d8.firebaseio.com",
      projectId: "smartline-992d8",
      storageBucket: "smartline-992d8.appspot.com",
      messagingSenderId: "576950966065",
      appId: "1:576950966065:web:b5a91f9c44d973efb9c210",
      measurementId: "G-CJB4PJQ53B",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    console.log("analytics : ", analytics);
    console.log("is not app");
  } catch (e) {
    console.log(e);
  }
}

require("@/assets/js/kakao.js");
(window as any).Kakao.init(Constant.kakao.javascriptKey);

Vue.config.productionTip = false;
// Vue.config.silent = true;

// // 이미지 viewer
// Vue.use(Viewer);
import VueTreeList from "vue-tree-list";

Vue.use(VueTreeList);

const options = {
  timeout: false,
  maxToasts: 1,
};

Vue.use(Toast, options);

Vue.use(Viewer);

// 공통 유틸 초기화
core.init(store, router, Vue.$toast);

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

Vue.component("top-toolbar", TopToolbar);

// 시간 선택 모달
Vue.component("scc-timepicker-modal", TimePickerModal);

// // 공통 App bar
// Vue.component("scc-filter-toolbar", FilterToolBar);
// Vue.component("scc-page-template", PageTemplate);
// Vue.component("scc-component-template", ComponentTemplate);

Vue.component("scc-component-template", ComponentTemplate);

// 아이템 검색 및 선택 모달
Vue.component("scc-select-search-modal", SelectSearchModal);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach((key) => {
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  console.log("error : ", error);
  core.http.sendError(error);
};

if (process.env.NODE_ENV === "production") {
  if ((window as any).console == undefined) (window as any).console = {};
  (window as any).console.log = function () {};
  (window as any).console.warn = function () {};
  (window as any).console.error = function () {};
}

// Vue.config.warnHandler = (msg, vm, trace) => {
//   // trace는 컴포넌트 계층 구조를 추적합니다.
//   console.log(`msg: ${msg}`);
//   console.log("trace : ", trace);
// };

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});
extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    // console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

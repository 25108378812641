
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import store from "@/store";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: {},
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
    visible: {
      // 박람회
      exposition: false,
    },
    modal: {
      appShare: {
        visible: false,
      },
    },
    schedulePage: "/schedule",
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = await store.getters["auth/user"]();
      await store.dispatch("topToolbar/changeTitle", {
        title: user.company.name,
        ignoreCheck: true,
      });

      // test code
      // this.app.isApp = true;
      // this.auth.systemProperties = {
      //   deviceToken:
      //     "dPFc1g2ORkuakVu3bE087P:APA91bG7Qa4nKNOBm8exmepLDT1raNmDXs4kyw42dcxTYG9Z6AyFfH44q13ZCeJKl3EFy-w_qH5v7dtdwvRHX_JXsxwB-F1u9TegMx0bONez1kUdDA_tD-5lbJKKjcaVJonvbeUox7Um",
      //   hardware: "ranchu",
      //   locale: "ko_KR",
      //   manufacturer: "Google",
      //   model: "sdk_gphone64_x86_64",
      //   os: "android",
      //   packageName: "kr.co.scc20.smartline",
      //   product: "sdk_gphone64_x86_64",
      //   timeZone: "Asia/Seoul",
      //   versionCode: 74,
      //   versionName: "1.0.14",
      //   versionRelease: "13",
      //   versionSdk: 33,
      // } as any;
      // this.auth.mobileSetting = { paymentSmsList: [], receiveCall: false };

      if (this.app.isApp) {
        if (this.app.platform === "android") {
          setTimeout(() => {
            if (this.auth.systemProperties != null) {
              const systemProperties = this.auth.systemProperties;
              if (systemProperties.versionCode != null) {
                const versionCode = Number(systemProperties.versionCode);
                if (!isNaN(versionCode)) {
                  if (versionCode < 73) {
                    core.alert
                      .show({
                        title: "알림",
                        body: "신규 앱이 플레이스토어에 배포되었습니다.<br/>스토어에서 기존 앱을 <span class='red--text'>제거</span> 후 새로 설치해주세요",
                        cancelButtonText: "취소",
                        showCancelButton: true,
                        confirmButtonText: "설치",
                      })
                      .then((result) => {
                        if (result === "confirm") {
                          location.href = "market://details?id=kr.co.scc20.smartline";
                        }
                      });
                  } else if (73 <= versionCode) {
                    // console.log("전화 수신 알림 사용 여부 체크");
                    this.changedMobileSetting();
                  }
                }
              }
            }
          }, 1000);
        }
      } else {
        // ios 이면서 앱이 설치 되어있지 않을때
        if (this.app.platform === "ios") {
          this.$store.dispatch("storage/getStorageItem", "confirm-ios-app-install").then((item) => {
            if (item == null) item = {};
            if (item.checkDate == null) {
              core.alert
                .show({
                  title: "알림",
                  body: "신규 앱이 앱스토어에 배포되었습니다.<br/>앱을 설치 하시겠습니까?",
                  cancelButtonText: "취소",
                  showCancelButton: true,
                  confirmButtonText: "설치",
                })
                .then((result) => {
                  item.checkDate = core.date.instance().format("YYYY-MM-DD");
                  store.dispatch("storage/setStorageItem", {
                    type: "confirm-ios-app-install",
                    items: item,
                  });
                  if (result === "confirm") {
                    location.href = Constant.iosStoreUrl;
                  }
                });
            }
          });
        }
      }
    });
  },
  methods: {
    changedMobileSetting() {
      //console.log("changedMobileSetting");
      const systemProperties = this.auth.systemProperties;
      if (systemProperties != null) {
        if (systemProperties.versionCode != null) {
          const versionCode = Number(systemProperties.versionCode);
          if (!isNaN(versionCode)) {
            if (73 <= versionCode) {
              // console.log("전화 수신 알림 사용 여부 체크");
              const mobileSetting = this.auth.mobileSetting;
              if (mobileSetting != null) {
                if (!mobileSetting.receiveCall) {
                  this.$store
                    .dispatch("storage/getStorageItem", "confirm-receive-call")
                    .then((item) => {
                      if (item == null) item = {};
                      if (item.checkDate == null) {
                        core.alert
                          .show({
                            title: "알림",
                            body: "고객 전화 수신 알림 서비스를 사용하시겠습니까?",
                            cancelButtonText: "아니오",
                            showCancelButton: true,
                            confirmButtonText: "예",
                          })
                          .then((result) => {
                            item.checkDate = core.date.instance().format("YYYY-MM-DD");
                            store.dispatch("storage/setStorageItem", {
                              type: "confirm-receive-call",
                              items: item,
                            });

                            if (result === "confirm") {
                              this.$router.push({
                                path: "/settings",
                                query: { action: "receiveCall" },
                              });
                            }
                          });
                      }
                    });
                }
              }
            }
          }
        }
      }
    },
    async showSchedulePage() {
      const items = (await this.$store.dispatch("storage/getStorageItem", "schedule-page")) as any;
      console.log("items : ", items);
      if (items != null && items.type) {
        this.$router.push("/schedule/" + items.type);
      } else {
        this.$router.push("/schedule/all");
      }
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
    showShareApplicationModal() {
      (window as any).Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "앱 다운로드 주소 공유",
          description: "",
          imageUrl: "https://manager.smart-line.co.kr/img/icons/android-icon-192x192.png",
          link: {
            mobileWebUrl: "https://manager.smart-line.co.kr/app-install",
            webUrl: "https://manager.smart-line.co.kr/app-install",
          },
        },
        buttons: [
          {
            title: "다운로드 받기",
            link: {
              mobileWebUrl: "https://manager.smart-line.co.kr/app-install",
              webUrl: "https://manager.smart-line.co.kr/app-install",
            },
          },
        ],
      });
    },
  },
});

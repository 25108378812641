
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import core from "@/core";
import NoticeService from "@/services/notice/notice.service";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "NoticeView",

  components: {},

  data: () => ({
    title: "공지",
    noticeId: 0,
    notice: null as any,
  }),
  mounted() {
    const params = this.$route.params as any;
    this.noticeId = Number(params.id);
    if (this.noticeId <= 0) {
      this.notFound();
    }

    this.$nextTick(async () => {
      try {
        const notice = await NoticeService.get(this.noticeId);
        console.log("notice : ", notice);
        this.notice = notice;
      } catch (e) {
        console.log(e);
        this.notFound();
      }
    });
  },

  watch: {},
  methods: {
    getHtml(content: string) {
      return core.utils.format.textToHtml(content);
    },
  },
});

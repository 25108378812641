
import CompanyService from "@/services/company/company.service";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";

export default mixins(MixinsPage).extend({
  name: "Company",
  data: () => ({}),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();
      if (user.company == null) {
        CompanyService.getCompanyList()
          .then((companyList: any) => {
            companyList.forEach(async (company: any) => {
              if (company.name === "스마트라인") {
                try {
                  const userCompany = await CompanyService.register({ id: company.id });
                  console.log("commit user company : ", userCompany);
                  await this.$router.push("/");
                } catch (e) {
                  console.log(e);
                }
              }
            });
          })
          .catch(async (reason) => {
            await core.alert.show({
              title: "알림",
              body: "오류가 발생했습니다.",
            });
            this.reloadPage();
          });
      } else {
        console.log("잘못된 호출");
      }
    });
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    unregister() {
      CompanyService.unregister();
    },
    register() {
      CompanyService.getCompanyList().then((companyList: any) => {
        companyList.forEach(async (company: any) => {
          if (company.name === "스마트라인") {
            try {
              const userCompany = await CompanyService.register({ id: company.id });
              console.log("commit user company : ", userCompany);
            } catch (e) {
              console.log(e);
            }
          }
        });
      });
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import ColorItems from "@/data/color-items.js";
import ScheduleSortModal from "@/modals/schedule/ScheduleSortModal.vue";
import MixinsStorage from "@/mixins/single/storage";
import ScheduleListViewTypeModal from "@/modals/schedule/ScheduleListViewTypeModal.vue";
import ScheduleUtils from "@/models/schedule";
import store from "@/store";
import TeamService from "@/services/team/team.service";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsModal, MixinsStorage).extend({
  name: "ScheduleDaySelectModal",
  components: {
    ScheduleListViewTypeModal,
    ScheduleSortModal,
  },
  data: () => ({
    viewType: "",
    title: "",
    scheduleList: [],
    searchColumns: {} as any,
    colorItems: ColorItems,
    colorHexItems: {} as any,
    date: "",
    totalPrice: 0,
    changedSort: false,
    sort: {
      orderColumnName: "id",
      order: "asc",
    } as any,
    listViewType: "default",
    modal: {
      sort: {
        visible: false,
        params: {
          sort: {
            orderColumnName: "id",
            order: "asc",
          },
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      listViewType: {
        visible: false,
        params: {
          listViewType: "default",
        },
        updateEvent: null as any as UpdateEvent | null,
      },
    },
    bodyMinHeight: "100px",
    myUser: {} as UserModel,
  }),
  created() {
    this.colorItems.forEach((item: any) => {
      this.colorHexItems[item.color] = item.hex;
    });
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();

      this.myUser = await this.$store.getters["auth/user"]();
      this.resize();

      this.searchColumns = core.utils.deepCopy(this.properties.params.searchColumns);
      this.viewType = this.properties.params.viewType;

      this.date = this.searchColumns.date;
      delete this.searchColumns.date;

      const startMoment = core.date.instance(this.date);
      const endMoment = core.date.instance(this.date).add(1, "days");

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = endMoment.format("YYYY-MM-DD");

      //console.log(this.searchColumns);

      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      // storage
      await this.storageWait();

      const storage = this.getStorageItems();
      if (storage) {
        if (storage.sort) {
          this.sort = storage.sort;
        }

        if (storage.listViewType) {
          this.listViewType = storage.listViewType;
        }
      }

      await this.getScheduleList();
      core.loader.hide();
    });
  },
  watch: {
    "app.size"() {
      this.resize();
    },
    "modal.sort.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.sort);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.sort = item;
          this.setStorageItem("sort", item);
          this.drawScheduleList();
          this.changedSort = true;
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.listViewType.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.listViewType);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.listViewType = item.listViewType;
          this.setStorageItem("listViewType", this.listViewType);
          this.drawScheduleList();
          this.changedSort = true;
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  destroyed() {
    if (this.changedSort) {
      this.setPropsUpdateEvent(UpdateEventType.RELOAD);
    }
  },
  methods: {
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 200;
      } else {
        if (this.app.size.width < 965) {
          offset = 215;
        } else {
          offset = 220;
        }
      }
      this.bodyMinHeight = this.app.size.height - offset + "px";
    },
    getTitle(item: any) {
      return ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, item);
    },
    getTime(item: any) {
      if (item.allDay) return "종일";

      const startMoment = core.date.instance(item.viewStartAt);
      // const startMoment = core.date.instance(item.startAt);

      const endMoment = core.date.instance(item.viewEndAt);
      // const endMoment = core.date.instance(item.endAt);

      return `${startMoment.format("a hh:mm")}<br>${endMoment.format("a hh:mm")}`;
    },
    getColorStyle(color: string) {
      const hex = this.colorHexItems[color];
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
    swipe(direction: string) {
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    next() {
      core.loader.show();
      const moment = core.date.instance(this.date).add(1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      this.getScheduleList();
      (this.$refs.body as HTMLElement).scrollTop = 0;
      core.loader.hide();
    },
    prev() {
      core.loader.show();
      const moment = core.date.instance(this.date).add(-1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      this.getScheduleList();
      (this.$refs.body as HTMLElement).scrollTop = 0;
      core.loader.hide();
    },
    async getScheduleList() {
      const scheduleList = (await ScheduleService.getBySearchColumns(this.searchColumns)) as any;
      //console.log("scheduleList : ", scheduleList);
      //console.log("this.searchColumns.workType : ", this.searchColumns.workType);
      if (
        this.searchColumns.workType != "V" &&
        (this.searchColumns.workTypeList == null ||
          this.searchColumns.workTypeList.indexOf("V") === -1)
      ) {
        if (this.isCompanyCounselorRoleHigher) {
          const vacationScheduleList = (await ScheduleService.getSearchColumnsAndViewType({
            workType: "V",
            startDate: this.searchColumns.startDate,
            endDate: this.searchColumns.endDate,
          })) as any;
          //console.log("vacationScheduleList : ", vacationScheduleList);
          vacationScheduleList.forEach((schedule) => {
            scheduleList.push(schedule);
          });
          await ScheduleService.convertScheduleList(this.searchColumns, scheduleList);
        } else {
          const teamUserMap = new Map();
          for (const team of this.myUser.company.teamList) {
            const teamUserList = (await TeamService.getUserList(team.id)) as any;
            teamUserList.forEach((user) => {
              teamUserMap.set(user.id, user);
            });
          }
          const vacationScheduleList = (await ScheduleService.getSearchColumnsAndViewType({
            workType: "V",
            startDate: this.searchColumns.startDate,
            endDate: this.searchColumns.endDate,
          })) as any;
          //console.log("vacationScheduleList : ", vacationScheduleList);
          vacationScheduleList.forEach((schedule) => {
            if (schedule.userList != null) {
              let addSchedule = false;
              schedule.userList.forEach((user) => {
                if (teamUserMap.has(user.id)) {
                  addSchedule = true;
                }
              });
              if (addSchedule) {
                scheduleList.push(schedule);
              }
            }
          });
          await ScheduleService.convertScheduleList(this.searchColumns, scheduleList);
        }
      }
      let totalPrice = 0;
      scheduleList.forEach((schedule: any) => {
        totalPrice += schedule.totalPrice as number;
      });
      this.scheduleList = scheduleList;
      this.totalPrice = totalPrice;
    },
    drawScheduleList() {
      let orderColumnName = undefined as string | undefined;
      let order = undefined as string | undefined;
      const sort = this.sort;
      if (sort != null) {
        orderColumnName = sort.orderColumnName;
        order = sort.order;
      }
      const listViewType = this.listViewType;
      ScheduleService.sortScheduleList(
        this.searchColumns,
        this.scheduleList,
        orderColumnName,
        order,
        listViewType
      );
    },
    showSortModal() {
      const modal = this.modal.sort;
      modal.params.sort = this.sort;
      modal.visible = true;
    },
    showListViewTypeModal() {
      const modal = this.modal.listViewType;
      modal.params.listViewType = this.listViewType;
      modal.visible = true;
    },
    async confirm() {
      // const result = await core.alert.show({
      //   title: "확인",
      //   body: `일정을 "${this.title}"로 선택하시겠습니까?`,
      //   showCancelButton: true,
      //   cancelButtonText: "아니오",
      //   confirmButtonText: "예",
      // });
      // if (result === "confirm") {
      //   this.close(UpdateEventType.CONFIRM, this.date);
      // }
      this.close(UpdateEventType.CONFIRM, this.date);
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import NoticeService from "@/services/notice/notice.service";
import { UpdateEventType } from "@/types";
import NoticeAddEditModal from "@/modals/notice/NoticeAddEditModal.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsComponent, MixinsTable, MixinsScroll).extend({
  name: "Notice",
  components: { NoticeAddEditModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "updatedAt",
        searchColumns: {},
      },
    },
    modal: {
      addEdit: {
        visible: false,
        params: {
          type: "add",
          item: {},
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as any as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: NoticeService,
      serviceFunctionKey: "getTable",
      itemHeight: 48,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      //console.log("event");
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
            // 현재 작성한 이벤트 페이지로 이동
            const item = event.item;
            this.$router.push({
              path: `/event/${item.id}`,
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.PAGE) {
          const item = event.item;
          const estimate = item.estimate;
          delete item.estimate;
          if (estimate != null) {
            if (this.addTableItem("id", estimate)) {
              this.setScrollPosition(ScrollPosition.TOP);
            }
          }
          // path, query
          this.$router.push(item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    showAddPage() {
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.visible = true;
    },
    showViewPage(item) {
      console.log("showViewPage : ", item.id);

      const modal = this.modal.addEdit;
      modal.params.type = "edit";
      modal.params.item = item;
      modal.visible = true;
      // this.$router.push({
      //   path: "/notice/" + item.id,
      // });
    },
  },
});

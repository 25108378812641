
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import store from "@/store";
import { cloneDeep } from "lodash";
import UnitPriceService from "@/services/unit-price/unit-price.service";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import UnitPriceAddEditCardItemComponent from "@/components/unit-price/AddEditCardItemComponent.vue";
import LoadUnitPriceModal from "@/modals/unit-price/LoadUnitPriceModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import PlaceService from "@/services/work/place.service";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import {
  SpecialUnitPriceImageModel,
  SpecialUnitPriceSrcImageModel,
} from "@/models/special-unit-price/special-unit-price.model";
import SpecialUnitPriceService from "@/services/special-unit-price/special-unit-price.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    place: "아파트 ",
    category: "품목 ",
    workScope: "작업범위 ",
    unitPrice: "단가 ",
    type: "타입 ",
    price: "금액 ",
    discount: "할인금액 ",
    excelFile: "엑셀 파일 ",
  },
});

export interface Category {
  id: number;
  name: string;
  typeList: Type[];
  workScope: {
    index: number;
    list: WorkScopeItem[];
  };
}

interface WorkScopeItem {
  index: number;
  name: string;
}

interface Type {
  index: number;
  name: string;
  workScopePriceList: WorkScopePrice[];

  imageList: SpecialUnitPriceImageModel[];
}

interface WorkScopePrice {
  index: number;
  name: string;
  price: number;
  priceModel: string;
  discount: number;
  discountModel: string;
}

export default mixins(MixinsPageForm).extend({
  name: "UnitPriceAddEditComponent",
  components: {
    SelectSearchModal,
    LoadUnitPriceModal,
    UnitPriceAddEditCardItemComponent,
    TextFieldModal,
  },
  props: {
    type: {
      type: String,
      default: "add",
    },
    properties: {
      type: Object,
      default: () => {
        return {
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
    // 장소 추가됬을 경우 변경됨
    place: {
      type: Object,
      default: () => {
        return null as any;
      },
    },
    category: {
      type: Object,
      default: () => {
        return null as any;
      },
    },
  },
  data: () => ({
    ready: false,
    processing: false,
    useSpecialPrice: false,
    view: {
      placeName: "",
    },
    tab: "",
    component: {
      cardItem: {
        categoryList: null as Category[] | null,
        updateEvent: null as UpdateEvent | null,
      },
    },
    modal: {
      place: {
        visible: false,
      },
      type: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 50,
          },
          title: "타입",
          type: "add",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
      workScope: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 255,
          },
          title: "작업범위",
          type: "add",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
      loadUnitPrice: {
        visible: false,
        list: [] as any,
        selected: null as any,
      },
    },
    form: {
      id: null as number | null,
      placeId: null as number | null,
      categoryList: [] as Category[],
    },
    orgPlaceList: [] as any,
    placeList: [] as any,

    categoryList: [] as any,

    selected: {
      place: null as any,
      category: null as Category | null,
    },
    app: store.state.app,
    placeType: {
      index: 0,
      list: [] as any,
    },
    unitPrice: null as any,
    specialUnitPriceSrcImageList: [] as SpecialUnitPriceSrcImageModel[],
    specialUnitPriceImageList: [] as SpecialUnitPriceImageModel[],
    visible: {
      loadUnitPriceButton: false,
    },
    uploadFile: null as File | null,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        this.categoryList = (await this.$store.getters["app/getCategoryList"](true)) as any;
        this.orgPlaceList = cloneDeep(await this.$store.getters["app/getPlaceList"]()) as any;

        await this.reloadCategory();
        await this.reloadPlace();

        if (this.type === "edit") {
          const query = this.$route.query;
          //console.log("query : ", query);
          if (this.isBlank(query.id)) {
            await this.notFound();
            return;
          }
          const unitPriceId = Number(query.id);
          await this.loadUnitPrice(true, unitPriceId);
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      this.ready = true;
    });
  },
  watch: {
    async category() {
      const categoryList = this.categoryList;

      const preFormCategoryList = this.form.categoryList;
      this.form.categoryList = [];
      categoryList.forEach((category) => {
        let item = null as any;
        preFormCategoryList.some((preFormCategory) => {
          if (preFormCategory.id === category.id) {
            item = preFormCategory;
            return true;
          }
        });
        if (item == null) {
          item = {
            id: category.id,
            name: category.name,
            typeList: [],
            workScope: {
              index: 0,
              list: [],
            },
          };
          const placeType = this.placeType;
          placeType.list.forEach((typeItem) => {
            item.typeList.push({
              index: typeItem.index,
              name: typeItem.name,
              workScopePriceList: [],
              imageList: [],
            });
          });
        }
        this.form.categoryList.push(item);
      });
    },
    place() {
      //console.log("change place : ", this.place);
      this.selected.place = this.place;
      this.view.placeName = this.place.name;
      this.form.placeId = this.place.id;
    },
    tab(tab) {
      // 품목 변경
      if (tab != null) {
        if (this.form.categoryList.length > 0) {
          this.selected.category = this.form.categoryList[Number(tab)];
        }
      }
    },
    "component.cardItem.updateEvent"(event) {
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.EVENT) {
          const eventItem = event.item;
          if (eventItem.type === "addWorkScope") {
            this.showAddWorkScopeModal();
          } else if (eventItem.type === "editWorkScope") {
            this.showEditWorkScopeModal(eventItem.item);
          } else if (eventItem.type === "deleteWorkScope") {
            this.deleteWorkScope(eventItem.item);
          } else if (eventItem.type === "typeItem") {
            this.showEditTypeModal(eventItem.item);
          } else {
            console.log("unknown type : ", eventItem.type);
          }
        }
      }
    },
    async "selected.place"(place, prePlace) {
      this.tab = "0";
      this.selected.category = null;

      if (place != null && place.id) {
        console.log("place : ", place);
        const placeList = this.orgPlaceList;
        const unitPricePlaceList = [] as any;
        placeList.forEach((_place) => {
          if (_place.typeList != null && place.id != _place.id) {
            unitPricePlaceList.push(_place);
          }
        });
        this.visible.loadUnitPriceButton = unitPricePlaceList.length > 0;

        if (this.type === "add") {
          this.clearPlaceType();
          await this.reloadCategory();
          await this.reloadPlace();

          // 선택된 품목 초기화
          if (this.form.categoryList.length > 0) {
            this.selected.category = this.form.categoryList[Number(this.tab)];
          }

          core.loader.show();
          try {
            // 단가표 확인
            const request = {
              draw: 0,
              start: 0,
              length: 10,
              orderColumnName: "id",
              order: "desc",
              searchColumns: {
                placeId: place.id,
              },
            } as any;
            const response = (await UnitPriceService.getTable(request)) as any;
            // console.log("response : ", response);
            if (response.data.length > 0) {
              const _unitPrice = response.data[0];
              // // redirect edit page
              // this.properties.updateEvent = new UpdateEvent(
              //   UpdateEventType.DEACTIVATE,
              //   this.$route.path,
              //   true
              // );
              // TODO : 수정 필요

              await this.$router.push({
                path: "/unit-price/edit",
                query: {
                  id: _unitPrice.id,
                },
              });
            }
          } catch (e) {
            console.log(e);
          }
          core.loader.hide();
        }
      }
    },
    "modal.type.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.type);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          // console.log("item : ", event.item);
          const modal = this.modal.type;
          const type = String(event.item);
          if (modal.params.type === "add") {
            // this.typeList.push(type);
            this.addPlaceType(type);
          } else if (modal.params.type === "edit") {
            // modal.params.item.name = type;
            this.editPlaceType(modal.params.item.index, type);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          // console.log("item : ", event.item);
          this.deletePlaceType(event.item);
        } else {
          console.log("unknown result : ", event);
        }
      }
    },
    "modal.workScope.updateEvent"(event) {
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const modal = this.modal.workScope;

          const workScope = String(event.item);
          if (modal.params.type === "add") {
            const category = this.selected.category;
            this.addWorkScope(category, workScope);
          } else if (modal.params.type === "edit") {
            this.editWorkScope(modal.params.item.index, workScope);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          //console.log("item : ", event.item);
          this.deleteWorkScope(event.item);
        } else {
          console.log("unknown result : ", event);
        }
      }
    },
    async "modal.loadUnitPrice.selected"(place) {
      if (place != null) {
        const unitPrice = (await UnitPriceService.getByPlaceId(place.id)) as any;
        //console.log("load unit-price : ", unitPrice);
        let result = "confirm";
        if (this.placeType.list.length > 0) {
          result = String(
            await core.alert.show({
              title: "확인",
              body: `현재 작성된 타입 및 금액이 "${place.name}"의 단가로 변경됩니다.<br>계속 진행하시겠습니까?`,
              showCancelButton: true,
            })
          );
        }
        if (result === "confirm") {
          this.clearPlaceType();
          this.reloadCategory();
          await this.loadUnitPrice(false, unitPrice.id, unitPrice);
        }
      }
    },
  },
  methods: {
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },
    async deleteImage(item: SpecialUnitPriceSrcImageModel) {
      console.log("delete image item : ", item);

      try {
        core.loader.show();
        const result = await SpecialUnitPriceService.deleteSrcImage(
          item.placeId,
          item.fileDetailId
        );
        //console.log("result : ", result);
        const length = this.specialUnitPriceSrcImageList.length;
        for (let i = 0; i < length; i++) {
          const image = this.specialUnitPriceSrcImageList[i];
          if (image.fileDetailId === item.fileDetailId) {
            this.specialUnitPriceSrcImageList.splice(i, 1);
            break;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      const placeId = this.unitPrice.placeId;
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = (await core.utils.image.getImageBlob(file)) as any;
            console.log("new image : ", imageFile);
            const formData = new FormData();
            formData.append("imageFile", imageFile.blob, imageFile.filename);
            core.loader.show(`이미지 업로드중... (${i + 1}/${length})`);
            const fileDetailId = Number(await SpecialUnitPriceService.uploadImage(formData));
            console.log("fileDetailId : ", fileDetailId);
            const srcImage = (await SpecialUnitPriceService.addSrcImage(
              placeId,
              fileDetailId
            )) as SpecialUnitPriceSrcImageModel;
            console.log("unitPriceImage : ", srcImage);
            this.specialUnitPriceSrcImageList.unshift(srcImage);
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
            return;
          }
        }
        core.loader.hide();

        (this.$refs.imageFile as any).value = "";
      }
    },
    clear() {
      this.clearPlaceType();
      this.reloadCategory();
    },
    async onChangeExcelFile() {
      console.log("change excel file");
      const el = this.$refs.excelFile as any;
      const files = el.files;
      const length = files.length;
      if (length > 0) {
        core.loader.show();
        for (let i = 0; i < length; i++) {
          const file = files[i];
          try {
            //console.log("file : ", file);
            const formData = new FormData();
            formData.append("excelFile", file);
            const response = (await UnitPriceService.convertExcel(formData)) as any;
            console.log("response : ", response);
            const errorMsgList = response.errorMsgList;
            if (errorMsgList != null && errorMsgList.length > 0) {
              let errorMsgText = "";
              errorMsgList.forEach((errorMsg: string) => {
                if (errorMsgText.length > 0) errorMsgText += "<br>";
                errorMsgText += errorMsg;
              });
              await core.alert.show({
                title: "알림",
                body: errorMsgText,
              });
            }
            const category = this.selected.category as any;
            const workScopeList = response.workScopeList;

            // 타입 추가
            const typeList = [] as string[];
            workScopeList.forEach((workScope) => {
              workScope.priceList.forEach((price) => {
                let exists = false;
                typeList.some((type) => {
                  if (type === price.type) {
                    exists = true;
                    return true;
                  }
                });
                if (!exists) {
                  typeList.push(price.type);
                }
              });
            });

            const placeTypeList = this.placeType.list;
            typeList.forEach((type) => {
              let exists = false;
              placeTypeList.some((placeType) => {
                if (placeType.name === type) {
                  exists = true;
                  return true;
                }
              });
              if (!exists) {
                this.addPlaceType(type);
              }
            });

            // 작업범위 추가
            workScopeList.forEach((workScope) => {
              const categoryWorkScope = category.workScope;
              let exists = false;
              categoryWorkScope.list.some((item) => {
                if (item.name === workScope.name) {
                  exists = true;
                  return true;
                }
              });
              if (!exists) {
                this.addWorkScope(category, workScope.name);
              }
              // 작업범위별 금액 추가
              category?.typeList.forEach((type) => {
                type.workScopePriceList.some((workScopePrice) => {
                  if (workScopePrice.name === workScope.name) {
                    workScope.priceList.some((price) => {
                      if (price.type === type.name) {
                        workScopePrice.priceModel = String(price.price / 10000);
                        workScopePrice.discountModel = String(price.discount / 10000);
                        return true;
                      }
                    });
                  }
                });
              });
            });
          } catch (e: any) {
            console.log(e);
            core.http.sendError(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        el.value = "";
      }
      core.loader.hide();
    },
    async convertExcel() {
      // 엑셀 파일 변환
      const file = this.$refs.excelFile as any;
      if (file != null) {
        file.click();
      }
    },
    async loadUnitPrice(changePlaceFlag, unitPriceId, unitPrice?) {
      try {
        if (unitPrice == null) {
          unitPrice = (await UnitPriceService.get(unitPriceId)) as any;
          // console.log("unitPrice : ", unitPrice);
        }
        const placeList = this.orgPlaceList;
        if (changePlaceFlag && placeList.length > 0) {
          this.unitPrice = unitPrice;
          this.form.placeId = unitPrice.place.id;
          this.view.placeName = unitPrice.place.name;
          placeList.some((place) => {
            if (place.id === unitPrice.place.id) {
              this.selected.place = place;
              console.log("selected.place : ", place);
              return true;
            }
          });
        }

        // 타입 추가
        const typeList = [] as string[];
        unitPrice.categoryList.forEach((category) => {
          category.workScopeList.forEach((workScope) => {
            workScope.priceList.forEach((price) => {
              let exists = false;
              typeList.some((type) => {
                if (type === price.type) {
                  exists = true;
                  return true;
                }
              });
              if (!exists) {
                typeList.push(price.type);
              }
            });
          });
        });
        typeList.forEach((type) => {
          this.addPlaceType(type);
        });

        // 작업범위 추가
        unitPrice.categoryList.forEach((unitPriceCategory) => {
          //console.log("unitPriceCategory : ", unitPriceCategory);
          this.form.categoryList.some((_category) => {
            //console.log("_category : ", _category);
            if (unitPriceCategory.categoryId === _category.id) {
              unitPriceCategory.workScopeList.forEach((workScope) => {
                this.addWorkScope(_category, workScope.name);
                // 작업범위별 금액 추가
                _category.typeList.forEach((type) => {
                  type.workScopePriceList.some((workScopePrice) => {
                    if (workScopePrice.name === workScope.name) {
                      workScope.priceList.some((price) => {
                        if (price.type === type.name) {
                          workScopePrice.priceModel = String(price.price / 10000);
                          workScopePrice.discountModel = String(price.discount / 10000);
                          return true;
                        }
                      });
                      return true;
                    }
                  });
                });
              });

              return true;
            }
          });
        });
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }

      // get unit price image list
      try {
        if (this.useSpecialPrice) {
          this.specialUnitPriceSrcImageList = (await SpecialUnitPriceService.getSrcImageList(
            unitPrice.placeId
          )) as SpecialUnitPriceSrcImageModel[];
          console.log("specialUnitPriceSrcImageList ", this.specialUnitPriceSrcImageList);

          this.specialUnitPriceImageList = (await SpecialUnitPriceService.getImageList(
            unitPrice.placeId
          )) as SpecialUnitPriceImageModel[];
          console.log("specialUnitPriceImageList ", this.specialUnitPriceImageList);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async reloadCategory() {
      const categoryList = this.categoryList;

      this.form.categoryList = [];
      this.selected.category = null;
      categoryList.forEach((category) => {
        const item = {
          id: category.id,
          name: category.name,
          typeList: [],
          workScope: {
            index: 0,
            list: [],
          },
        };
        this.form.categoryList.push(item);
      });

      if (this.form.categoryList.length > 0) {
        this.selected.category = this.form.categoryList[Number(this.tab)];
      }
    },
    async reloadPlace() {
      this.placeList = [];
      const placeList = this.orgPlaceList;
      placeList.forEach((place) => {
        // console.log("place : ", place);
        if (place.typeList == null) {
          // 단가 없는 장소만 추가
          this.placeList.push(cloneDeep(place));
        }
      });
      this.orgPlaceList = placeList;
    },
    clearPlaceType() {
      const placeType = this.placeType;
      placeType.index = 0;
      placeType.list = [];
    },
    async addPlaceType(name) {
      const placeType = this.placeType;

      const typeItem = { index: placeType.index++, name: name };
      // 중복된 타입 검사
      let exists = false;
      placeType.list.some((_typeItem) => {
        if (_typeItem.name === name) {
          exists = true;
          return true;
        }
      });
      if (exists) {
        await core.alert.show({
          title: "알림",
          body: "중복된 타입이 존재합니다",
        });
        return;
      }
      placeType.list.push(typeItem);

      const categoryList = this.form.categoryList;
      categoryList.forEach((category) => {
        const workScopePriceList = [] as WorkScopePrice[];
        category.workScope.list.forEach((workScopeItem) => {
          workScopePriceList.push({
            index: workScopeItem.index,
            name: workScopeItem.name,
            price: 0,
            priceModel: "",
            discount: 0,
            discountModel: "",
          });
        });

        category.typeList.push({
          index: typeItem.index,
          name: typeItem.name,
          workScopePriceList: workScopePriceList,
          imageList: [],
        });

        category.typeList.sort((a: Type, b: Type) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          else if (a.name === b.name) return 0;
          else return -1;
        });
      });

      // console.log("categoryList : ", categoryList);
      // console.log("category : ", this.selected.category);
    },
    async editPlaceType(index, name) {
      const placeType = this.placeType;

      // 중복된 타입 검사
      let exists = false;

      let typeItem = null as Type | null;
      placeType.list.some((_typeItem) => {
        if (index === _typeItem.index) {
          typeItem = _typeItem;
        } else if (index !== _typeItem.index && _typeItem.name === name) {
          exists = true;
          return true;
        }
      });

      if (typeItem == null) {
        await core.alert.show({
          title: "알림",
          body: "타입을 찾을 수 없습니다",
        });
        return;
      } else if (exists) {
        await core.alert.show({
          title: "알림",
          body: "중복된 타입이 존재합니다",
        });
        return;
      } else {
        typeItem.name = name;
        //console.log("change type : ", typeItem);
        const categoryList = this.form.categoryList;
        categoryList.forEach((category) => {
          category.typeList.some((type) => {
            if (type.index === typeItem?.index) {
              type.name = typeItem?.name;
              return true;
            }
          });
        });
      }
    },
    async deletePlaceType(item: Type) {
      const placeType = this.placeType;
      //console.log("delete work scope : ", item);
      const result = await core.alert.show({
        title: "확인",
        body: `"${item.name}" 타입을 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result !== "confirm") {
        return;
      }

      {
        const list = placeType.list;
        for (let i = 0; i < list.length; i++) {
          const type = list[i];
          if (type.index === item.index) {
            list.splice(i, 1);
            break;
          }
        }
      }
      const categoryList = this.form.categoryList;
      categoryList.forEach((category) => {
        const list = category.typeList;
        for (let i = 0; i < list.length; i++) {
          const type = list[i];
          if (type.index === item.index) {
            list.splice(i, 1);
            break;
          }
        }
      });
    },
    async addWorkScope(category, name) {
      if (category == null) return;

      const workScope = category.workScope;
      const workScopeItem = { index: workScope.index++, name: name };
      // 중복된 작업범위 검사
      let exists = false;
      workScope.list.some((item) => {
        if (item.name === name) {
          exists = true;
          return true;
        }
      });
      if (exists) {
        await core.alert.show({
          title: "알림",
          body: "중복된 작업범위 존재합니다",
        });
        return;
      }
      workScope.list.push(workScopeItem);

      //console.log("add workScopeItem : ", workScopeItem);
      category.typeList.forEach((typeItem) => {
        typeItem.workScopePriceList.push({
          index: workScopeItem.index,
          name: workScopeItem.name,
          price: 0,
          priceModel: "",
          discount: 0,
          discountModel: "",
        });
      });
    },
    async editWorkScope(index, name) {
      const category = this.selected.category;
      if (category == null) return;

      const workScope = category.workScope;

      workScope.list.some((item) => {
        if (item.index === index) {
          item.name = name;
          return true;
        }
      });
      category.typeList.forEach((typeItem) => {
        typeItem.workScopePriceList.some((item) => {
          if (item.index === index) {
            item.name = name;
            return true;
          }
        });
      });
    },
    async deleteWorkScope(item: WorkScopePrice) {
      //console.log("delete work scope : ", item);
      const result = await core.alert.show({
        title: "확인",
        body: `"${item.name}" 작업범위를 삭제하시겠습니까?`,
        showCancelButton: true,
      });
      if (result !== "confirm") {
        return;
      }

      const category = this.selected.category;
      if (category == null) return;

      const workScope = category.workScope;

      const list = workScope.list;
      for (let i = 0; i < list.length; i++) {
        const workScopeItem = list[i];
        //console.log("workScopeItem : ", workScopeItem);
        if (workScopeItem.index === item.index) {
          list.splice(i, 1);
          break;
        }
      }
      //console.log("list : ", list);

      category.typeList.forEach((typeItem) => {
        const list = typeItem.workScopePriceList;
        for (let i = 0; i < list.length; i++) {
          const workScopeItem = list[i];
          if (workScopeItem.index === item.index) {
            list.splice(i, 1);
            break;
          }
        }
      });
    },
    showAddWorkScopeModal() {
      const modal = this.modal.workScope;
      modal.params.type = "add";
      modal.params.enableDeleteButton = false;
      modal.visible = true;
    },
    showEditWorkScopeModal(item: WorkScopePrice) {
      const modal = this.modal.workScope;
      modal.params.type = "edit";
      modal.params.text = item.name;
      modal.params.item = item;
      modal.params.enableDeleteButton = true;
      modal.visible = true;
    },
    showAddTypeModal() {
      const modal = this.modal.type;
      modal.params.type = "add";
      modal.params.enableDeleteButton = false;
      modal.visible = true;
    },
    showEditTypeModal(typeItem) {
      const modal = this.modal.type;
      modal.params.type = "edit";
      modal.params.text = typeItem.name;
      modal.params.item = typeItem;
      modal.params.enableDeleteButton = true;
      modal.visible = true;
    },
    async showSelectPlaceModal() {
      await this.reloadPlace();
      const modal = this.modal.place;
      modal.visible = true;
    },
    async showLoadUnitPriceModal() {
      const modal = this.modal.loadUnitPrice;
      modal.list = [];

      const selectedPlace = this.selected.place;
      if (selectedPlace != null) {
        // 단가 목록 추가
        const placeList = this.orgPlaceList;
        placeList.forEach((place) => {
          if (place.typeList != null && selectedPlace.id != place.id) {
            modal.list.push(place);
          }
        });
        modal.visible = true;
      }
    },
    async createPlace(keyword, itemList) {
      try {
        if (itemList != null && itemList.length > 0) {
          let placeName = "";
          itemList.forEach((item) => {
            placeName += item.name + ",";
          });
          placeName = placeName.substr(0, placeName.length - 1);
          const result = await core.alert.show({
            title: "확인",
            body: `비슷한 이름의 [<span class="red--text">${placeName}</span>] 장소가 존재합니다.<br>새로 생성하시겠습니까?`,
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "생성",
          });
          if (result !== "confirm") return;
        }
        // const item = (await PlaceService.create({ name: keyword, color: "green" })) as PlaceModel;
        // this.placeList.push(item);
        // return item;
        const item: any = await PlaceService.create({ name: keyword });
        await this.$store.dispatch("app/waitForPlaceUpdate", item.id);
        return item;
      } catch (e: any) {
        if (e.status === 400) {
          this.placeList = await this.$store.getters["app/getPlaceList"]();
        }
      }
    },
    async submit() {
      if (await this.validate()) {
        try {
          console.log("저장중??");
          core.loader.show("저장중...");

          const params = {
            placeId: this.form.placeId,
            categoryList: [] as any,
          };

          this.form.categoryList.forEach((category) => {
            const categoryParam = {
              categoryId: category.id,
              workScopeList: [] as any,
            };
            const workScopeList = [] as any;

            category.workScope.list.forEach((workScope) => {
              workScopeList.push({
                name: workScope.name,
                priceList: [],
              });
            });

            category.typeList.forEach((type) => {
              type.workScopePriceList.forEach((workScopePrice) => {
                workScopeList.some((workScope) => {
                  // console.log(
                  //   "workScope : ",
                  //   workScope.name,
                  //   ", workScopePrice : ",
                  //   workScopePrice.name
                  // );
                  if (workScope.name === workScopePrice.name) {
                    if (
                      this.isNotBlank(workScopePrice.priceModel) ||
                      this.isNotBlank(workScopePrice.discountModel)
                    ) {
                      let price = 0;
                      if (this.isNotBlank(workScopePrice.priceModel)) {
                        price = Number(workScopePrice.priceModel) * 10000;
                      }
                      let discount = 0;
                      if (this.isNotBlank(workScopePrice.discountModel)) {
                        discount = Number(workScopePrice.discountModel) * 10000;
                      }

                      workScope.priceList.push({
                        type: type.name,
                        price: price,
                        discount: discount,
                      });
                    }
                  }
                });
              });
            });

            for (let i = 0; i < workScopeList.length; i++) {
              const workScope = workScopeList[i];
              if (workScope.priceList.length > 0) {
                categoryParam.workScopeList.push(workScope);
              }
            }

            if (categoryParam.workScopeList.length > 0) {
              params.categoryList.push(categoryParam);
            }
            //console.log("categoryParam : ", categoryParam);
          });

          if (params.categoryList.length > 0) {
            console.log("params : ", params);
            if (this.type === "add") {
              const unitPrice = await UnitPriceService.create(params);
              console.log("create unitPrice : ", unitPrice);
              this.goBack(UpdateEventType.UPDATE, unitPrice);
            } else {
              const unitPrice = await UnitPriceService.update(Number(this.unitPrice?.id), params);
              console.log("update unitPrice : ", unitPrice);
              this.goBack(UpdateEventType.UPDATE, unitPrice);
            }
          } else {
            await core.alert.show({
              title: "알림",
              body: "추가된 내용이 없습니다",
            });
            return;
          }
        } catch (e: any) {
          console.log(e);
          core.alert.show({ title: "오류", body: e.message });
        } finally {
          core.loader.hide();
        }
      }
    },
    async deleteItem() {
      const result = (await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      })) as string;

      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const response = await UnitPriceService.delete(Number(this.unitPrice?.id));
          console.log("response : ", response);
          core.loader.hide();
          this.goBack(UpdateEventType.DELETE, this.unitPrice);
        } catch (e) {
          console.log(e);
          core.loader.hide();
        }
      }
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import UnitPriceGroupService from "@/services/unit-price/unit-price-group.service";
import core from "@/core";

export default mixins(MixinsModal, MixinsTable, MixinsScroll).extend({
  name: "SelectUnitPriceGroupModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            placeId: -1,
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    table: {
      body: null as any,
      defaultColumns: [],
    },
    search: {
      keyword: "",
      submitKeyword: "",
      loading: false,
      readonly: false,
    },
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      interval: null as any,
      intervalCount: 0,
      control: {
        scrollTop: 0,
        date: null as any as Date | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const scroll = this.scroll;
      const $el = this.$refs.body as any;
      this.table.body = $el;

      // 테이블 초기화
      this.initTable({
        service: UnitPriceGroupService,
        serviceFunctionKey: "getTable",
        itemHeight: 110,
      });
      this.table.request.searchColumns = { placeId: this.properties?.params.placeId };

      scroll.eventFunc = function () {
        scroll.scrollTop = $el.scrollTop;
        scroll.scrollHeight = $el.scrollHeight;
        scroll.clientHeight = $el.clientHeight;
        //console.log("scrollTop : ", $el.scrollTop);
      };

      $el.addEventListener("scroll", scroll.eventFunc);

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
      //console.log("change scrollTop : ", scrollTop);
    },
  },
  destroyed() {
    //console.log("destroyed");
    const $el = this.$refs.body as any;
    if ($el != null) {
      $el.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  methods: {
    async deleteItem(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `"${item.name}" 단가 그룹을 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          console.log("item : ", item);
          const result = await UnitPriceGroupService.delete(item.id);
          this.deleteTableItem("id", item);
          // console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }
      }
    },
    selectItem(item) {
      this.close(UpdateEventType.CONFIRM, item);
    },
    async submit() {
      //console.log("submit");
      const keyword = this.search.keyword.trim();
      this.search.submitKeyword = keyword;
      if (keyword.length > 0) {
        this.loadTable({ nameLike: keyword });
        //console.log("keyword : ", keyword);
      } else {
        this.loadTable({});
      }
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateComponent from "@/components/estimate/EstimateComponent.vue";
import UpdateEvent from "@/models";
import AppBar from "@/components/core/AppBar.vue";
import { cloneDeep } from "lodash";
import { LayoutType } from "@/router";

export default mixins(MixinsPage).extend({
  name: "Estimate",
  components: { EstimateComponent, AppBar },
  data: () => ({
    type: "",
    ready: false,
    properties: {
      visible: false,
      updateEvent: null as UpdateEvent | null,
    },
    filter: {
      selected: null as any,
      selectedItem: null as any,
      items: [] as any,
    },
    prop: null as any,
  }),
  mounted() {
    this.$nextTick(() => {
      if (this.isCompanyCounselorRoleHigher) {
        this.filter.items = [
          { id: "default", text: "기본" },
          { id: "deleted", text: "삭제" },
          { id: "issue", text: "이슈" },
          { id: "tempPaymentYn", text: "임시결제" },
          { id: "me", text: "본인작성" },
        ];
      } else {
        this.filter.items = [
          { id: "default", text: "기본" },
          { id: "issue", text: "이슈" },
          { id: "me", text: "본인작성" },
        ];
      }
      const meta = this.$route.meta as any;
      const layoutList = cloneDeep(meta.layoutList) as any;
      layoutList.push(LayoutType.APP_BAR_NAV);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };
      this.changedFilter(0);
    });
  },
  watch: {
    "filter.selected"(selected) {
      this.changedFilter(selected);
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.properties.updateEvent = event;
    }
  },
  methods: {
    changedFilter(selected) {
      if (selected != null) {
        const selectedItem = this.filter.items[selected];
        if (selectedItem) {
          console.log("selectedItem : ", selectedItem);
          this.filter.selectedItem = selectedItem;
          this.type = selectedItem.id;
          this.properties.visible = true;
        } else {
          console.log("not found item : ", selected);
        }
      }
    },
  },
});

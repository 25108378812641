
import mixins from "vue-typed-mixins";
import EstimateService from "@/services/work/estimate.service";
import MixinsTable from "@/mixins/single/table";
import UpdateEvent from "@/models";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import MixinsComponent from "@/mixins/component";
import EstimateSortModal from "@/modals/estimate/EstimateSortModal.vue";
import MixinsStorage from "@/mixins/single/storage";
import EstimateUtils from "@/models/estimate";
import core from "@/core";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { UserModel } from "@/models/user/user.model";
import EstimateListItemComponent from "@/components/estimate/ListItemComponent.vue";

export default mixins(
  MixinsComponent,
  MixinsScroll,
  MixinsTable,
  MixinsStorage,
  MixinsAppBarMenu
).extend({
  name: "EstimateComponent",
  components: { EstimateListItemComponent, EstimateSortModal },
  props: {
    type: {
      type: String,
    },
  },
  data: () => ({
    storageItems: {
      type: "estimate",
    },

    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "createdAt",
        searchColumns: {},
      },
    },
    sort: {
      orderColumnName: "updatedAt",
      order: "desc",
    } as any,
    modal: {
      sort: {
        visible: false,
        params: {
          sort: {
            orderColumnName: "updatedAt",
            order: "desc",
          },
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    visible: {
      addButton: true,
    },
    user: {} as UserModel,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.user = await this.$store.getters["auth/user"]();
      this.changedType();

      this.appBarChangeMenuVisible("download", this.isCompanyAdminRoleHigher);

      // 테이블 초기화
      this.initTable({
        service: EstimateService,
        serviceFunctionKey: "getTable",
        itemHeight: 48,
      });

      const query = this.$route.query as any;
      if (query.action === "add") {
        this.replaceState();
        this.showAddPage(query.phone);
      } else if (query.action === "view") {
        if (query.estimateId != null) {
          this.replaceState();
          this.showViewPage(Number(query.estimateId));
        }
      } else if (query.action === "search") {
        this.replaceState();
        this.$router.push({ path: `/estimate/search` });
      }

      // storage
      await this.storageWait();

      const storage = this.getStorageItems();
      if (storage) {
        if (storage.sort) {
          this.sort = storage.sort;
        }
      }

      if (this.sort != null) {
        this.table.request.orderColumnName = this.sort.orderColumnName;
        this.table.request.order = this.sort.order;
      }

      if (this.isNotBlank(query.placeId)) {
        this.table.request.searchColumns.placeId = query.placeId;
      }

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    type(type) {
      this.changedType();
    },
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
            // 현재 작성한 견적서 페이지로 이동
            const item = event.item;
            this.$router.push({
              path: `/estimate/${item.id}`,
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
          // if (this.properties.params.type === "deleted") {
          //   // 견적서 복구됨
          //   this.$router.push({
          //     path: `/estimate/${event.item.id}`,
          //   });
          // }
        } else if (event.result === UpdateEventType.PAGE) {
          const item = event.item;
          const estimate = item.estimate;
          delete item.estimate;
          if (estimate != null) {
            if (this.addTableItem("id", estimate)) {
              this.setScrollPosition(ScrollPosition.TOP);
            }
          }
          // path, query
          this.$router.push(item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.sort.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.sort);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.sort = item;
          this.setStorageItem("sort", item);

          if (this.sort != null) {
            this.table.request.orderColumnName = this.sort.orderColumnName;
            this.table.request.order = this.sort.order;
          }

          // this.scrollControl.position = ScrollPosition.TOP;
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    changedType() {
      const type = this.type;
      // console.log("changed type : ", type);
      if (this.type === "issue") {
        this.table.request.searchColumns = {
          issue: true,
        };
        this.visible.addButton = false;
      } else if (this.type === "deleted") {
        this.table.request.searchColumns = {
          deletedAtIsNull: false,
        };
        this.visible.addButton = false;
      } else if (this.type === "tempPaymentYn") {
        this.table.request.searchColumns = {
          tempPaymentYn: "Y",
        };
        this.visible.addButton = false;
      } else if (this.type === "me") {
        this.table.request.searchColumns = {
          createdBy: this.user.username,
        };
        this.visible.addButton = false;
      } else {
        this.table.request.searchColumns = {};
        this.visible.addButton = true;
      }

      if (!this.table.firstLoading) {
        this.clearTable();
        this.table.firstLoading = true;
        this.loadTable();
      }
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "search") {
        const query = {} as any;
        if (this.isNotBlank(this.type)) {
          query.type = this.type;
        }
        this.$router.push({
          path: "/estimate/search",
          query: query,
        });
      } else if (menu.id === "sort") {
        const modal = this.modal.sort;
        modal.params.sort = this.sort;
        modal.visible = true;
      } else if (menu.id === "download") {
        this.$router.push({
          path: "/estimate/download",
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    replaceState(urlParams?: any) {
      if (urlParams == null) urlParams = {};
      urlParams.stackKey = this.$route.query.stackKey;
      const length = Object.keys(urlParams).length;
      const url = this.$route.path + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
      history.replaceState("", "", url);
    },
    showViewPage(estimateId: number) {
      // 수정 필요
      if (this.type === "deleted") {
        this.$router.push({
          path: `/estimate/${estimateId}`,
          query: { type: this.type },
        });
      } else {
        this.$router.push({ path: `/estimate/${estimateId}` });
      }
    },
    showAddPage(phone?: string) {
      const query = {} as any;
      if (phone != null) {
        query.phone = phone;
      }
      this.$router.push({ path: `/estimate/add`, query: query });
    },

    titleToHtml(item: any) {
      return EstimateUtils.titleToHtml(item);
    },
    scheduleToHtml(item: any) {
      return EstimateUtils.scheduleToHtml(item);
    },
    contractStatusToHtml(item: any) {
      return EstimateUtils.contractStatusToHtml(item);
    },
    captionToHtml(item: any) {
      if (item.totalPrice > 0) {
        if (item.paymentYn === "Y") {
          return `견적가 ${this.moneyFormat(item.totalPrice)}, 결제 완료`;
        } else {
          return `견적가 ${this.moneyFormat(item.totalPrice)}, 결제 ${this.moneyFormat(
            item.payment
          )}, 잔금 ${this.moneyFormat(item.balance)}`;
        }
      }
      return "";
    },
  },
});

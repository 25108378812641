import core from "@/core";
import { TableRequest } from "@/types";

class NoticeService {
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/notice/${id}`, null)
        .then((data: any) => {
          resolve(data.notice);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/notice/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/notice`, strParams)
        .then((data: any) => {
          if (data.notice != null) {
            resolve(data.notice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/notice/${id}`, strParams)
        .then((data: any) => {
          if (data.notice != null) {
            resolve(data.notice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/notice/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new NoticeService();


import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { UpdateEventType } from "@/types";
import CompanyService from "@/services/company/company.service";
import ScheduleService from "@/services/work/schedule.service";
import TeamService from "@/services/team/team.service";

export default mixins(MixinsModalForm).extend({
  name: "ScheduleUserEditModal",
  data: () => ({
    formProcessing: false,
    userList: [] as any,
    subUserList: [] as any,
    selectedUserId: null as number | null,
    selectedSubUserIdList: [] as any,
    selectedUserList: [] as any,
    ready: false,
  }),
  async created() {
    // 생성 후 호출
    const schedule = this.properties.params.schedule;
    if (schedule.category != null) {
      const myUser = await this.$store.getters["auth/user"]();
      if (this.isCompanyCounselorRoleHigher) {
        //console.log("schedule : ", schedule);
        if (schedule.teamId != null) {
          // 사용자 목록 조회
          this.userList = await TeamService.getUserList(schedule.teamId);
        } else {
          this.userList = await CompanyService.getUserList({
            lastApprovalType: "ALLOW",
          });
        }
        //console.log(this.userList);
      } else {
        if (myUser.company.teamList != null) {
          for (const team of myUser.company.teamList) {
            if (team.role === "LEADER" && schedule.teamId === team.id) {
              // 사용자 목록 조회
              this.userList = await TeamService.getUserList(team.id);
            }
          }
        }
      }
      this.userList.splice(0, 0, {
        id: null,
        name: "없음",
      });
      //console.log("schedule : ", schedule);
      const selectedUserList = this.properties.params.schedule.userList;
      selectedUserList.forEach((user) => {
        if (user.type === "MAIN") {
          this.selectedUserId = user.id;
        } else {
          this.selectedSubUserIdList.push(user.id);
        }
        // this.selectedUserList.push(user.id);
      });

      this.changedUserId();

      this.ready = true;
    } else {
      console.log("카테고리 정보 없음!!");
    }
  },
  watch: {
    selectedUserId() {
      if (this.ready) {
        this.changedUserId();
      }
    },
  },
  mounted() {
    // 페이지 로딩 후 호출
  },
  methods: {
    changedUserId() {
      if (this.selectedUserId != null) {
        const subUserList = [] as any;
        const subUserMap = {};
        this.userList.forEach((user) => {
          if (this.selectedUserId != user.id && user.id != null) {
            subUserList.push(user);
            subUserMap[user.id] = user;
          }
        });

        const selectedSubUserIdList = this.selectedSubUserIdList;
        const length = selectedSubUserIdList.length;
        //console.log("selectedSubUserIdList : ", selectedSubUserIdList, ", length : ", length);
        for (let i = 0; i < length; i++) {
          const userId = selectedSubUserIdList[i];
          if (!subUserMap[userId]) {
            //console.log("subUserList : ", subUserList, ", delete userId : ", userId);
            selectedSubUserIdList.splice(i, 1);
            break;
          }
        }
        this.subUserList = subUserList;
      }
    },
    async submit() {
      // console.log("selectedUserList : ", this.selectedUserList);
      // console.log("selectedUserId : ", this.selectedUserId);
      // console.log("selectedSubUserId : ", this.selectedSubUserIdList);
      const scheduleParam = this.properties.params.schedule;

      const selectedUserList = [] as any;
      if (this.selectedUserId != null) {
        selectedUserList.push(this.selectedUserId);
      }
      this.selectedSubUserIdList.forEach((userId) => {
        selectedUserList.push(userId);
      });
      const schedule = await ScheduleService.updateUserList(scheduleParam.id, selectedUserList);
      // console.log("update schedule : ", schedule);
      this.close(UpdateEventType.UPDATE, schedule);
    },
  },
});

import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { ApprovalType, AppState, CompanyRole, RootState, TableOrder } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import core from "@/core";
import { Subscribe, SubscribeType } from "@/core/core-web-socket";
import { NotificationModel } from "@/models/user/notification.model";
import { PlaceModel } from "@/models/place/place.model";
import PlaceService from "@/services/work/place.service";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import { NavMenu, NavMenuId, NavMenuType, NavSubMenu } from "@/models/core/nav-menu.model";
import NavMenuItems from "@/data/nav-menu-items";
import { cloneDeep } from "lodash";
import { hasCompanyRole } from "@/store/modules/auth";
import SupportService from "@/services/support/support.service";
import CategoryService from "@/services/work/category.service";
import { CategoryModel } from "@/models/category/category.model";
import TeamService from "@/services/team/team.service";
import { TeamModel } from "@/models/team/team.model";

const state: AppState = {
  version: process.env.VUE_APP_VERSION || "0",
  name: Constant.appName,
  size: { width: 0, height: 0, bodyWidth: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  path: "",
  showNav: true,
  bottomNav: "",
  platform: "",
  isApp: false,
  isMobile: false,
  isMobileSize: false,
  webSocketConnected: false,
  data: {
    place: {
      ready: false,
      first: true,
      list: [] as PlaceModel[],
    },
    category: {
      ready: false,
      first: true,
      list: [] as CategoryModel[],
    },
    team: {
      ready: false,
      first: true,
      list: [] as TeamModel[],
    },
    supportCount: {
      ready: false,
      count: 0,
    },
    notificationList: [] as NotificationModel[],
  },
  navMenuList: [] as NavMenu[],
};

export const getters: GetterTree<AppState, RootState> = {
  webSocketConnected: (theState: AppState) => {
    return theState.webSocketConnected;
  },
  getPlaceList: (theState: AppState) => {
    return (): Promise<PlaceModel[] | null> => {
      return new Promise((resolve, reject) => {
        const dataFunction = () => {
          const place = theState.data.place;
          if (place.first) {
            place.first = false;
            mutations.loadAppStateItem(theState, {
              dataId: "place",
              service: PlaceService,
              serviceFunctionKey: "getList",
              subscribeType: SubscribeType.PLACE,
            });
          }
          if (place.ready) {
            resolve(place.list);
          } else {
            setTimeout(dataFunction, 100);
          }
        };
        dataFunction();
      });
    };
  },
  getCategoryList: (theState: AppState) => {
    return (estimate?: boolean): Promise<CategoryModel[] | null> => {
      return new Promise((resolve, reject) => {
        const dataFunction = () => {
          const category = theState.data.category;
          if (category.first) {
            category.first = false;
            mutations.loadAppStateItem(theState, {
              dataId: "category",
              service: CategoryService,
              serviceFunctionKey: "getList",
              subscribeType: SubscribeType.CATEGORY,
            });
          }
          if (category.ready) {
            if (estimate) {
              const list = [] as CategoryModel[];
              category.list.forEach((category) => {
                if (
                  category.name === "나노코팅" ||
                  category.name === "줄눈" ||
                  category.name === "청소" ||
                  category.name === "탄성코트"
                ) {
                  list.push(category);
                }
              });
              resolve(list);
            } else {
              resolve(category.list);
            }
          } else {
            setTimeout(dataFunction, 100);
          }
        };
        dataFunction();
      });
    };
  },
  getTeamList: (theState: AppState) => {
    return (): Promise<TeamModel[] | null> => {
      return new Promise((resolve, reject) => {
        const dataFunction = () => {
          const team = theState.data.team;
          if (team.first) {
            team.first = false;
            // mutations.loadTeam(theState);
            mutations.loadAppStateItem(theState, {
              dataId: "team",
              service: TeamService,
              serviceFunctionKey: "getList",
              subscribeType: SubscribeType.TEAM,
            });
          }
          if (team.ready) {
            resolve(team.list);
          } else {
            setTimeout(dataFunction, 100);
          }
        };
        dataFunction();
      });
    };
  },
};

export const actions: ActionTree<AppState, RootState> = {
  webSocketConnected({ commit }, data) {
    commit("webSocketConnected", data);
  },
  webSocketDisconnect() {
    core.webSocket.disconnect();
  },
  waitForPlaceUpdate({ commit }, placeId: number) {
    return new Promise((resolve: any, reject) => {
      const startTime = new Date().getTime();
      const func = async () => {
        console.log("waitForPlaceUpdate placeId : ", placeId);
        const curTime = new Date().getTime();
        const elapsedTimeMillis = curTime - startTime;
        // 1초 만 대기함
        if (elapsedTimeMillis < 1000) {
          const placeList = await store.getters["app/getPlaceList"]();
          if (placeList != null) {
            const length = placeList.length;
            for (let i = length - 1; i > 0; i--) {
              const place = placeList[i];
              if (place.id === placeId) {
                console.log("waitForPlaceUpdate done");
                resolve();
                return;
              }
            }
          }
          setTimeout(func, 50);
        }
      };
      func();
    });
  },
};

const mutations: MutationTree<AppState> = {
  async webSocketConnected(theState, data) {
    const connected = data.connected;
    const subscribeList: Subscribe[] | null = data.subscribeList;

    const preWebSocketConnected = theState.webSocketConnected;
    theState.webSocketConnected = connected;

    if (preWebSocketConnected != connected) {
      // console.log("change web-socket connected : ", connected);

      if (connected) {
        // console.log("subscribeList : ", subscribeList);
        const subscribeTypes = [] as SubscribeType[];
        if (subscribeList != null) {
          subscribeList.forEach((subscribe) => {
            if (subscribe.type != null) {
              subscribeTypes.push(subscribe.type);
              core.webSocket.subscribe(subscribe.type, subscribe.item, subscribe.callback);
            }
          });
        }
        await mutations.loadNavMenu(theState);
        if (!(subscribeTypes.indexOf(SubscribeType.SUPPORT_COUNT) > -1)) {
          mutations.loadSupportCount(theState);
        }
      }
    }
  },
  async loadNavMenu(theState) {
    const user = await store.getters["auth/user"]();
    if (user == null) {
      console.log("not found user");
    } else {
      console.log("loadNavMenu");
      const menuList = [] as NavMenu[];
      for (const orgMenu of NavMenuItems) {
        const menu = cloneDeep(orgMenu);
        if (menu.type === NavMenuType.DIVIDER) {
          if (menuList.length > 0) {
            // 이전 메뉴가 divider 일 경우 무시
            const preMenu = menuList[menuList.length - 1];
            if (preMenu.type === NavMenuType.DIVIDER) {
              continue;
            }
          }
          menuList.push(menu);
        } else {
          if (menu.id != null) {
            if (menu.id === NavMenuId.logout) {
              menu.click = () => {
                store.dispatch("auth/logoutPage");
              };
            } else if (menu.id === NavMenuId.notification) {
              if (menu.badge) {
                if (theState.data.notificationList.length > 0) {
                  menu.badge.content = String(theState.data.notificationList.length);
                } else {
                  menu.badge.content = "";
                }
              }
            } else if (menu.id === NavMenuId.schedule) {
              if (user.company != null && user.company.lastApproval.type === ApprovalType.ALLOW) {
                // 일정 메뉴 서브 메뉴 추가
                const subMenuList = [] as NavSubMenu[];
                menu.subMenuList = subMenuList;

                subMenuList.push({
                  path: "/all",
                  title: "전체",
                  companyRoles: [
                    CompanyRole.OWNER,
                    CompanyRole.ADMIN,
                    CompanyRole.MANAGER,
                    CompanyRole.USER,
                  ],
                  divider: false,
                });
                subMenuList.push({
                  path: "/default",
                  title: "일반",
                  companyRoles: [
                    CompanyRole.OWNER,
                    CompanyRole.ADMIN,
                    CompanyRole.MANAGER,
                    CompanyRole.USER,
                  ],
                  divider: false,
                });
                subMenuList.push({
                  path: "/vacation",
                  title: "휴무",
                  companyRoles: [
                    CompanyRole.OWNER,
                    CompanyRole.ADMIN,
                    CompanyRole.MANAGER,
                    CompanyRole.USER,
                  ],
                  divider: false,
                });
                subMenuList.push({
                  path: "/attendance",
                  title: "출근",
                  companyRoles: [
                    CompanyRole.OWNER,
                    CompanyRole.ADMIN,
                    CompanyRole.MANAGER,
                    CompanyRole.USER,
                  ],
                  divider: false,
                });

                const categoryList: CategoryModel[] = await store.getters["app/getCategoryList"]();
                const teamList = [] as TeamModel[];
                if (
                  hasCompanyRole(
                    user,
                    CompanyRole.OWNER,
                    CompanyRole.ADMIN,
                    CompanyRole.MANAGER,
                    CompanyRole.COUNSELOR
                  )
                ) {
                  if (categoryList.length > 0) {
                    // 품목별 일정 추가
                    let first = true;
                    categoryList.forEach((category: CategoryModel) => {
                      subMenuList.push({
                        divider: first,
                        path: "/category-" + category.id,
                        title: category.name,
                        companyRoles: [
                          CompanyRole.OWNER,
                          CompanyRole.ADMIN,
                          CompanyRole.MANAGER,
                          CompanyRole.COUNSELOR,
                        ],
                      });
                      first = false;
                    });
                  }

                  ((await store.getters["app/getTeamList"]()) as TeamModel[]).forEach((team) => {
                    teamList.push(team);
                  });
                } else {
                  if (user.company && user.company.teamList) {
                    user.company.teamList.forEach((team) => {
                      teamList.push(team);
                    });
                  }
                }
                if (teamList.length > 0) {
                  // teamList 품목, 이름 순 정렬
                  const categoryMap = {};
                  teamList.forEach((team) => {
                    const categoryName = team.category.name;
                    if (categoryMap[categoryName] == null) categoryMap[categoryName] = [];
                    categoryMap[categoryName].push(team);
                  });

                  teamList.length = 0;
                  categoryList.forEach((category) => {
                    const key = category.name;
                    const list = categoryMap[key];
                    if (list != null) {
                      list.sort((a: any, b: any) => {
                        if (a.name < b.name) return -1;
                        else if (a.name > b.name) return 1;
                        return 0;
                      });
                      list.forEach((item) => {
                        if (item.enabled) {
                          teamList.push(item);
                        }
                      });
                    }
                  });

                  let first = true;
                  teamList.forEach((team) => {
                    subMenuList.push({
                      divider: first,
                      path: "/team-" + team.id,
                      title: `[${team.category.name}] ${team.name}팀`,
                      companyRoles: [
                        CompanyRole.OWNER,
                        CompanyRole.ADMIN,
                        CompanyRole.MANAGER,
                        CompanyRole.COUNSELOR,
                        CompanyRole.USER,
                      ],
                    });
                    first = false;
                  });
                }
              }
            } else if (menu.id === NavMenuId.support || menu.id === NavMenuId.supportAs) {
            } else {
              console.log("invalid menuId : ", menu.id);
            }
          }

          if (menu.subMenuList) {
            const subMenuList = [] as NavSubMenu[];
            menu.subMenuList.forEach((subMenu) => {
              if (subMenu.companyRoles == null || hasCompanyRole(user, ...subMenu.companyRoles)) {
                subMenu.path = menu.path + subMenu.path;
                subMenuList.push(subMenu);
              }
            });
            if (subMenuList.length > 0) {
              if (subMenuList.length === 1) {
                const subMenu = subMenuList[0];
                menu.path = subMenu.path;
                delete menu.subMenuList;
                menuList.push(menu);
              } else {
                menu.subMenuList = subMenuList;
                menuList.push(menu);
              }
            }
          } else if (menu.companyRoles == null || hasCompanyRole(user, ...menu.companyRoles)) {
            menuList.push(menu);
          }
        }
      }
      theState.navMenuList = menuList;
    }
  },
  async loadSupportCount(theState) {
    const user = await store.getters["auth/user"]();
    if (hasCompanyRole(user, CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER)) {
      const supportCountData = theState.data.supportCount;
      const getDataList = async () => {
        try {
          const params = {
            draw: 0,
            start: 0,
            length: 1,
            orderColumnName: "createdAt",
            order: TableOrder.DESC,
            searchColumns: {
              activated: true,
              commentCountGreaterThan: 0,
            },
          };
          const result = (await SupportService.getTable(params)) as any;
          const count = result.recordsTotal;
          supportCountData.count = count;
          theState.navMenuList.some((menu) => {
            if (menu.id === NavMenuId.support) {
              if (menu.badge) {
                if (count > 0) {
                  menu.badge.content = String(count);
                } else {
                  menu.badge.content = "";
                }
              }
              return true;
            }
          });
        } catch (e) {
          console.log(e);
        }
      };
      await getDataList();

      core.webSocket.subscribe(
        SubscribeType.SUPPORT_COUNT,
        {
          companyId: user.company.id,
          callback: async (event) => {
            console.log("changed support count : ", event);
            const item = event.item;
            let menu = null as NavMenu | null;
            theState.navMenuList.some((navMenu) => {
              if (navMenu.id === NavMenuId.support) {
                menu = navMenu;
                return true;
              }
            });
            if (menu) {
              if (menu.badge) {
                if (item > 0) {
                  menu.badge.content = String(item);
                } else {
                  menu.badge.content = "";
                }
              }
            } else {
              console.log("not found navMenu : ", menu);
            }
          },
        },
        {
          connected: async () => {
            if (!supportCountData.ready) {
              await getDataList();
              supportCountData.ready = true;
            }
          },
          disconnected: () => {
            supportCountData.ready = false;
          },
        }
      );
      supportCountData.ready = true;
    }
  },
  async loadAppStateItem(theState, params) {
    const dataId: string = params.dataId;
    const service: any = params.service;
    const serviceFunctionKey: string = params.serviceFunctionKey;
    const subscribeType: SubscribeType = params.subscribeType;

    const user = (await store.getters["auth/user"]()) as UserModel;
    const stateData = theState.data[dataId];
    if (stateData == null) {
      console.log("not found state data : ", dataId);
      return;
    }
    const getDataList = async () => {
      try {
        // console.log("loadCalendar");
        stateData.list.length = 0;

        const list = (await service[serviceFunctionKey]()) as any;
        //console.log("list : ", list);
        list.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          return 0;
        });
        list.forEach((team) => {
          stateData.list.push(team);
        });
      } catch (e) {
        console.log(e);
      }
    };
    await getDataList();

    // 장소 정보 변경 알림 구독 처리
    if (
      user.company != null &&
      user.company.lastApproval != null &&
      user.company.lastApproval.type == ApprovalType.ALLOW
    ) {
      core.webSocket.subscribe(
        subscribeType,
        {
          companyId: user.company.id,
          callback: async (event) => {
            // console.log("changed place : ", event);
            const stateDataList = stateData.list;
            const crud = event.crud;
            if (crud === "C" || crud === "U") {
              const item = event.item as PlaceModel;

              for (let i = 0; i < stateDataList.length; i++) {
                const stateData = stateDataList[i];
                if (stateData.id === item.id) {
                  stateDataList.splice(i, 1);
                  break;
                }
              }

              stateDataList.push(item);
              stateDataList.sort((a, b) => {
                if (a.name < b.name) return -1;
                else if (a.name > b.name) return 1;
                return 0;
              });
            } else if (crud === "D") {
              const deleteId = Number(event.item);
              for (let i = 0; i < stateDataList.length; i++) {
                const stateData = stateDataList[i];
                if (stateData.id === deleteId) {
                  stateDataList.splice(i, 1);
                  console.log("delete " + dataId + " : ", stateData);
                  break;
                }
              }
            }
          },
        },
        {
          connected: async () => {
            if (!stateData.ready) {
              await getDataList();
              stateData.ready = true;
            }
          },
          disconnected: () => {
            stateData.ready = false;
          },
        }
      );
    }
    stateData.ready = true;
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
